const helpRegistry = {
  agentList: `TODO: This is the agent list!`,
  agentRating: 'Rate this agent on how well you feel they could represent your work using a scale of 1-10',
  customFormDate: `Custom form date setting`,
  doNotQuery: `Sometimes you might want to track agents you wish NOT to query. Check this box to separate these agents from those you do wish to query.`,
  editor: `Add your query template text. Be sure to include any text substitutions!`,
  editAgentForm: `Edit Agent form`,
  formattedText: `The example text below shows how your query will appear to an agent. Text substitutions appear in purple for demo purposes only and won’t be highlighted in the final query.`,
  pagesDropdown: `Agents will state the type of writing sample they require. If Query Manager, this value will be stated in the form.`,
  pagesEditor: `Copy/paste the chapter text into the editor below and make any needed formatting changes.`,
  pagesEditorDropdown: `Select the chapter you wish to enter in the dropdown, then copy/paste the text into the editor`,
  queryBuilderEmailSubject: `The default subject line of your query emails. Email subjects can be customized later if the agent’s requires a custom subject line, otherwise Querier will use this default.`,
  textSubstitution: `Text substitutions allow you to personalize queries by adding custom text. Create the text substitutions here and place inside your query (using the ‘{{your_text_substitution}}’ format). When you write the query, you’ll be able to personalize the text for each agent, focusing only on this small bit of text.\n\nExample text is used for demo purposes on this page only and will not be included in your queries.`,
  // textSubstitution: `Text substitutions allow you to customize each query by replacing the substitution with actual text. To create a text substitution:
  // 1. Click the ‘+’ button
  // 2. Give the substitution a name
  // 3. Add the text signifier: a word wrapped in double curly braces (eg, "{{my_text}}")
  // 4. Add an Example Text to test in the Formatted Text field. This text will appear in purple for illustration purposes only.
  // 5. Add the signifier in the query template text.
  // 6. Save the substitution!`,
  queryManagerFinalText: `TODO: Query Manager final email explanation`,
  queryBuilderAgent: `TODO: This is query builder agent tooltip`,
  queryBuilderAgentReqs: `TODO: This is query builder agent reqs tooltip`,
  queryBuilderSubstitutionEntries: `TODO: This is query builder sub entries`,
  queryBuilderEditor: `TODO: This is query builder editor tooltip`,
  queryFormat: `Agents will request a specific submission format for you query, often asking authors to submit via email, QueryManager, or using their custom form. Select the agent's required submission type here.`,
  queryManagerDate: `Input date the query was submitted to QM`,
  queryManagerPersonalLink: 'TODO: QueryManagerPersonalLink info',
  responseTime: `Agents may state how long you can expect to wait for a reply. Querier can use this timeframe to help you track your query.`,
  substitutionFormCancel: `Click to discard your subsitution changes`,
  substitutionFormDelete: `Click to delete your substitution`,
  substitutionFormSubmit: `Click to save your substitution`,
}

export const getTooltipText = (key) => {
  return helpRegistry[key]
}
