
//===================================================================================
// External
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// Internal
import Substitution from '../Substitution/Substitution';
import PageSubtitle from '../PageSubtitle/PageSubtitle';
import { getSubstitutions } from '../../store/slices/substitutions.slice';
//===================================================================================

const SubstitutionList = () => {
  const substitutions = useSelector(getSubstitutions);
  const [showNewSubstitution, setShowNewSubstitution] = useState(false);

  const openNewSubstitution = () => {
    setShowNewSubstitution(!showNewSubstitution);
  }

  const existingSubstitutionList = Object.values(substitutions).map((substitution, index) => {
    return (
      <Substitution
        setShowNewSubstitution={setShowNewSubstitution}
        substitution={substitution}
        keyValue={index}
      />
    )
  })

  return (
    <div className="SubstitutionList__container">
      <PageSubtitle
        tooltipKey='textSubstitution'
        title='Text Substitutions'
        onClickHandler={openNewSubstitution}
        shouldShowAddIcon
        shouldShowQuestionMark
      />
      <div className='SubstitutionList__list'>
        {
          showNewSubstitution &&
          <Substitution
            substitution={{}} // empty substitution
            showNewSubstitution={showNewSubstitution}
            setShowNewSubstitution={setShowNewSubstitution}
          />
        }
        {existingSubstitutionList}
      </div>
    </div>
  )
}

export default SubstitutionList;