//===================================================================================
// External
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Internal
import { getProjects } from '../../store/slices/projects.slice';
import { useNavigate } from 'react-router-dom';
//===================================================================================

const useSidebarHooks = () => {
  const projects = useSelector(getProjects);
  const navigate = useNavigate();
  const [shouldShowProjectLinks, setShouldShowProjectLinks] = useState("");
  const [shouldShowQueryLinks, setShouldShowQueryLinks] = useState("");
  const [collapsed, setCollapsed] = React.useState(false);
  const [toggled, setToggled] = React.useState(false);
  const [broken, setBroken] = React.useState(false);
  const dispatch = useDispatch();

  const shouldDisplayDropdownContent = (title) => {
    return shouldShowProjectLinks === title
  };

  const handleProjectClick = ({ title, _id }) => {
    if (title === 'Project Header') {
      setShouldShowProjectLinks(''); // collapse indiv proj links
      navigate(`/dashboard`);
    } else {
      setShouldShowProjectLinks(title);
      navigate(`/project-dashboard/${_id}`);
    }
  };

  return {
    broken,
    collapsed,
    handleProjectClick,
    dispatch,
    navigate,
    projects,
    setBroken,
    setCollapsed,
    setShouldShowProjectLinks,
    setShouldShowQueryLinks,
    setToggled,
    shouldDisplayDropdownContent,
    shouldShowProjectLinks,
    shouldShowQueryLinks,
    toggled,
  }
}

export default useSidebarHooks;
