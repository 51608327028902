//==============================================================================
// Internal:
import AgentImage from "../../assets/query-agent.jpg";
import QueryTemplate from "../../assets/home_1.png";
import AgentList from "../../assets/home_2.png";
import CreateQuery from "../../assets/home_3.png";
import SubmitQuery from "../../assets/home_4.png";
import TrackQuery from "../../assets/home_5.png";
//==============================================================================

export const setImage = (image) => {
  switch (image) {
    case 'agent': {
      return AgentImage;
    }
    case 'createQuery': {
      return CreateQuery;
    }
    case 'agentList': {
      return AgentList;
    }
    case 'queryTemplate': {
      return QueryTemplate;
    }
    case 'submitQuery': {
      return SubmitQuery;
    }
    case 'trackQuery': {
      return TrackQuery;
    }
    default:
      return AgentImage
  }
}