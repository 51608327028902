//==============================================================================
// External:
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Internal:
import { getPaymentInfo, getUserDetails } from '../../store/slices/user.slice';
import fetchAPI from '../../utils/fetchAPI';
import {
  formatDayAndDate,
  isSubscriptionActive,
  isSubscriptionInactive,
  isWithinTrialPeriod,
} from '../../utils/utils';
import environment from '../../utils/env';
import PageTitleBar from '../../components/PageTitleBar/PageTitleBar';
import PageSubtitle from '../../components/PageSubtitle/PageSubtitle';
import { useNavigate } from 'react-router-dom';
import { deleteUser } from '../../store/actions/actions';
import AccountUser from '../../components/AccountUser/AccountUser';
//==============================================================================

const Accout = ({ }) => {
  const paymentInfo = useSelector(getPaymentInfo) || {};
  const [billingPortal, setBillingPortal] = useState('');
  const { firstName = '', lastName = '' } = useSelector(getUserDetails);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const getStripeBillingPortal = async () => {
      const { session } = await fetchAPI(`${environment.dbBaseURL}/billing-portal`)
      setBillingPortal(session.url)
    }
    getStripeBillingPortal();
  }, []);

  const displayTrialOrSubscriptionText = () => {
    const subscriptionActive = isSubscriptionActive(paymentInfo);
    const withinTrialPeriod = isWithinTrialPeriod(paymentInfo);
    const subscriptionInactive = isSubscriptionInactive(paymentInfo);
    if (subscriptionActive) return <ActiveSubscriptionInfo />
    if (withinTrialPeriod) return <TrialInfo />;
    if (subscriptionInactive) return <InactiveSubscriptionInfo />
    return <div className='Account__text'>Please visit the Billing Center to manage your subscription.</div>
  }

  const TrialInfo = () => {
    return (
      <div className='Account__textContainer'>
        <div className='Account__text'>Your trial period will end on {formatDayAndDate(paymentInfo.currentPeriodEnd * 1000)}.</div>
        <div className='Account__text'>Please visit the Billing Center to manage your subscription.</div>
      </div>
    )
  }

  const ActiveSubscriptionInfo = () => {
    return (
      <div className='Account__textContainer'>
        <div className='Account__text'>Your subscription will renew on {formatDayAndDate(paymentInfo.currentPeriodEnd * 1000)}.</div>
        <div className='Account__text'>Please visit the Billing Center to manage your subscription.</div>
      </div>
    )
  }

  const InactiveSubscriptionInfo = () => {
    return (
      <div className='Account__textContainer'>
        <div className='Account__text'>Your subscription is inactive. Please visit the Billing Center to reactiveate.</div>
      </div>
    )
  }

  const onDeleteAccount = () => {
    dispatch(deleteUser({ navigate }));
  }

  const setRenewalTerm = () => {
    if (paymentInfo.status === 'trialing') return 'Trial End Date:';
    if (paymentInfo.status === 'active') return 'Renewal Date:';
    return '';
  }

  const setRenewalDate = () => {
    if (paymentInfo.status === 'trialing' || paymentInfo.status === 'active') {
      return formatDayAndDate(paymentInfo.currentPeriodEnd * 1000);
    }
    return 'NA';
  }

  return (
    <div className='Account__container'>
      <PageTitleBar title='Account' subTitle={`Hello, ${firstName} ${lastName}!`} />
      <div className='Account__innerContainer'>
        <div className='Account__paymentContainer'>
          <AccountUser />
          <PageSubtitle title='Subscription Information' />
          <div className='Account__statusContainer'>
            <div className='Account__statusLabel bold'>Account Status:</div>
            <div className='Account__status'>{paymentInfo.status}</div>
          </div>
          <div className='Account__statusContainer'>
            <div className='Account__statusLabel bold'>{`${setRenewalTerm()}`}</div>
            <div className='Account__status'>{setRenewalDate()}</div>
          </div>
          {displayTrialOrSubscriptionText()}
          <button>
            <a
              className='Account__paymentButton'
              href={billingPortal}
            >
              Billing Center
            </a>
          </button>
        </div>
        <div className='Account__actionsContainer'>
          <PageSubtitle title='Account Management' />
          <div className='Account__buttonsContainer'>
            <button
              className='Account__deleteButton red'
              onClick={() => onDeleteAccount()}
            >
              Delete Account
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Accout;
