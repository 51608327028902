//===================================================================================
// External
import React from 'react';
import { useNavigate } from 'react-router-dom';

// Interna
import { useGetAgents } from '../../hooks/commonHooks';
import {
  formateDate,
  formatQueryType,
  isClosedStatus,
  isDesktop,
  monthNamesAbbr,
  rowColor,
  timeDiffInDays
} from '../../utils/utils';
//===================================================================================

const QueryCard = ({ query, number }) => {
  const navigate = useNavigate();
  const {
    _id: queryId,
    agentId,
    dateSentToAgent,
    dateSentToInbox,
    status,
    createdAt,
    updatedAt,
  } = query;
  const agent = useGetAgents()[String(agentId)];

  const {
    firstName,
    lastName,
    agency,
    responseTime
  } = agent;

  const handleDateStatus = () => {
    if ((!status || status === 'waiting') && dateSentToAgent) {
      return `Sent to Agent ${timeDiffInDays(dateSentToAgent)} Day(s) Ago`
    }

    if ((!status || status === 'waiting') && dateSentToInbox) {
      return `Sent to 'Drafts' ${timeDiffInDays(dateSentToInbox)} Day(s) Ago`
    }

    if (status === 'manuscript-request-full') return 'Manuscript Request - Full';
    if (status === 'manuscript-request-partial') return 'Manuscript Request - Partial';
    if (status === 'revise-resubmit') return 'Revise & Resubmit';
    if (status === 'rejection-form') return 'Form Rejection (Closed)';
    if (status === 'rejection-personal') return 'Personal Rejection (Closed)';
    if (status === 'representation-offer') return 'Representation Offer (Closed)';
    if (status === 'no-response') return 'No Response (Closed)';
    return `Started`
  }

  const handleNavigate = () => {
    if (!query.queryFormat || query.queryFormat === 'Email') {
      if (!query.dateSentToInbox) {
        navigate(`/query/${queryId}`, { state: { queryId } })
        return;
      }

      if (query.dateSentToAgent) {
        navigate(`/query-thread/${queryId}`) //nav to new page
        return;
      }

      if (query.dateSentToInbox) {
        navigate(`/query-final-review/${queryId}`) //nav to new page
        return;
      }
    }

    if (query.queryFormat === 'QueryManager') {
      if (query.queryManagerPersonalLink) {
        navigate(`/query-manager-review/${queryId}`)
        return;
      } else {
        navigate(`/query-manager-submit/${queryId}`)
        return;
      }
    }

    if (query.queryFormat === 'CustomForm') {
      navigate(`/agent-custom-form-review/${queryId}`)
      return;
    }
  }

  const calcResponseTime = () => {
    if (!dateSentToAgent || !responseTime) return '--';
    if (isClosedStatus(query.status)) return 'Closed';

    // format dateSentToAgent:
    const dateSentToAgentObj = new Date(query.dateSentToAgent);
    const dateSentToAgentMillis = new Date(dateSentToAgentObj).getTime();

    // get future date when response time expires:
    const totalResponseTimeMillis = responseTime * 7 * 24 * 60 * 60 * 1000; // time in weeks
    const futureDateMillis = totalResponseTimeMillis + dateSentToAgentMillis;
    const futureDateObj = new Date(futureDateMillis)
    const futureDateFormatted = `${monthNamesAbbr[futureDateObj.getMonth()]} ${futureDateObj.getDate()}, ${futureDateObj.getFullYear()}`;

    const today = Date.now();

    const diffTime = futureDateObj - today;
    const diffDays = Math.floor(diffTime / (24 * 60 * 60 * 1000));

    if (diffDays > 0) return (<div>{diffDays} Days ({futureDateFormatted})</div>);
    if (diffDays <= 0) return (<div><span className="redText bold">{diffDays} Days</span> ({futureDateFormatted})</div>);
  }

  const shouldStrikethrough = () => {
    return isClosedStatus(query.status) ? 'strikethrough' : '';
  }

  const DesktopCard = () => {
    return (
      <tr
        className={`QueryCard__container`}
        onClick={() => handleNavigate()}
        style={{ backgroundColor: rowColor(number) }}
      >
        <td>{number}</td>
        <td className={`QueryCard__name ${shouldStrikethrough()}`}>
          {firstName} {lastName}
        </td>
        <td className='QueryCard__agency'>
          {agency}
        </td>
        <td className='QueryCard__status'>
          {handleDateStatus()}
        </td>
        <td>
          {formatQueryType(query.queryFormat)}
        </td>
        <td className='QueryCard__responeTime'>
          {calcResponseTime()}
        </td>
        <td>{updatedAt ? formateDate(updatedAt) : formateDate(createdAt)}</td>
      </tr>
    )
  }

  const MobileCard = () => {
    return (
      <div
        className='QueryCardMobile__container'
        onClick={() => handleNavigate()}
        style={{ backgroundColor: rowColor(number) }}
      >
        <div className='QueryCardMobile__nameNumberContainer'>
          <div className='QueryCardMobile__nameContainer'>
            <div className='QueryCardMobile__number'>{`${number}.`}</div>
            <div className={`QueryCard__name ${shouldStrikethrough()}`}>
              {firstName} {lastName}
            </div>
          </div>
          <div className='QueryCard__agency'>
            {agency}
          </div>
        </div>
        <div className='QueryCardMobile__status'>
          <div className='QueryCardMobile__label'>Status:</div>
          {handleDateStatus()}
        </div>
        <div className='QueryCardMobile__format'>
          <div className='QueryCardMobile__label'>Format:</div>
          {formatQueryType(query.queryFormat)}
        </div>
        <div className='QueryCardMobile__responseTime'>
          <div className='QueryCardMobile__label'>Response Time:</div>
          {calcResponseTime()}
        </div>
        {/* <div>{updatedAt ? formateDate(updatedAt) : formateDate(createdAt)}</div> */}
      </div>
    )
  }

  const cardToDisplay = isDesktop() ? <DesktopCard /> : <MobileCard />;

  return (
    cardToDisplay
  )
}

export default QueryCard;
