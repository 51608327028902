//==============================================================================
// External:
import React from 'react';

// Internal:
import { formatFinalPages } from '../../../../components/QueryFinalEmail/QueryFinalEmailUtils';
//==============================================================================

const SubmitToQueryManager = ({
  agent,
  query,
  queryTemplate,
  user
}) => {

  const copyStuff = async () => {
    const copyText = {
      querier: true,
      // referral: 'Chewbacca',
      authorBlog: queryTemplate.authorBlog,
      authorTwitter: queryTemplate.authorTwitter,
      authorWebsite: queryTemplate.authorWebsite,
      biography: queryTemplate.biography,
      bookPreviouslyPublished: queryTemplate.bookPreviouslyPublished,
      email: user.email,
      firstName: user.firstName,
      genre: queryTemplate.genre,
      hasBeenPreviouslyPublished: queryTemplate.hasBeenPreviouslyRepd,
      hasBeenPreviouslyRepd: queryTemplate.hasBeenPreviouslyRepd,
      lastName: user.lastName,
      phoneNumber: queryTemplate.phoneNumber,
      pitch: queryTemplate.pitch,
      queryLetter: query.finalEmailText,
      similarBooks: queryTemplate.similarBooks,
      samplePages: formatFinalPages('{{pages}}', agent, queryTemplate.chapterOneText, queryTemplate.chapterTwoText, queryTemplate.chapterThreeText, queryTemplate.chapterFourText, queryTemplate.chapterFiveText),
      synopsis: queryTemplate.synopsis,
      targetAudience: queryTemplate.targetAudience,
      title: queryTemplate.bookTitle,
      wordCount: queryTemplate.wordCount,
    }

    await navigator.clipboard.writeText(JSON.stringify(copyText));
    window.open(agent.queryManager, '_blank').focus();
    console.log("Copied the text: " + JSON.stringify(copyText));
  };


  return (
    <div className='SubmitToQueryManager__container'>
      <div className='SubmitToQueryManager__agentInfoContainer'>
        <div className='QueryManagerSubmit__text'>
          <p>To quickly and easily submit your query to QueryManager:</p>
          <ol type="1">
            <li>Add the <a href='https://google.com'>Querier Browser Extension</a> to your browser</li>
            <li>Click the 'Go To QueryManager' button below</li>
            <li>Querier will open a new tab with the correct QueryManager form.</li>
            <li>Open this new tab and click the Querier extension in your browser's extension bar.</li>
            <li>Click the 'Submit to QueryManager' button and Querier will populate the form with your query information!</li>
            <li><b>IMPORTANT:</b> Review the form to ensure all fields have been filled out, then click QueryManager's 'Submit Query' button at the bottom of the page.</li>
          </ol>
        </div>
        <button
          className='SubmitToQueryManager__qtCopyButton'
          onClick={() => copyStuff()}
        >
          Go To QueryManager
        </button>
      </div>
    </div>
  )
}

export default SubmitToQueryManager;