//==============================================================================
// External:
import React from 'react';
import { useDispatch } from 'react-redux';

// Internal:
import {
  APP_DELETE_USER,
  APP_KEEP_USER,
} from '../../utils/actionConstants';
//==============================================================================

const DeleteUserConfirmationModal = () => {
  const dispatch = useDispatch();

  const onDelete = () => {
    dispatch({type: APP_DELETE_USER})
  }

  const onClose = () => {
    dispatch({type: APP_KEEP_USER});
  }

  return (
    <div className='DeleteProjectConfirmationModal__container'>
      <div className='DeleteProjectConfirmationModal__textContainer'>
        <div>Are you sure you want to delete your account?</div>
        <div>This action will delete all data associated with this profile.</div>
        <div>Once it's been deleted, it can't be recovered!</div>
      </div>
      <div className='DeleteProjectConfirmationModal__buttonContainer'>
        <button
          className='DeleteProjectConfirmationModal__keepButton'
          onClick={() => onClose()}
        >
          Keep Account
        </button>
        <button
          className='DeleteProjectConfirmationModal__deleteButton'
          onClick={() => onDelete()}
        >
          Delete Account
        </button>
      </div>
    </div>
  )
}

export default DeleteUserConfirmationModal;