//==============================================================================
// External:
import React from 'react';

// Internal:
import HomeCard from './HomeCard';
import MobileHomeCard from './MobileHomeCard';
import {
  HOME_CARD_WRITE,
  HOME_CARD_AGENT,
  HOME_CARD_BUILD,
  HOME_CARD_SUBMIT,
  HOME_CARD_TRACK
} from '../../../utils/constants';
import { isDesktop } from '../../../utils/utils';
//==============================================================================

const HowDoesQuerierWork = () => {
  const assembleProps = {
    title: 'Assemble Your Query Template',
    text: HOME_CARD_WRITE,
    link: '/about',
    image: 'queryTemplate',
  }

  const buildProps = {
    title: 'Build Your Agent List',
    text: HOME_CARD_AGENT,
    image: 'agentList',
    link: '/about',
  }

  const createProps = {
    title:'Create Your Query',
    text: HOME_CARD_BUILD,
    image: 'createQuery',
    link: '/about',
  }

  const submitProps = {
    title: 'Submit Your Query',
    text: HOME_CARD_SUBMIT,
    image: 'submitQuery',
    link: '/about',
  }

  const trackProps = {
    title: 'Track Your Query',
    text: HOME_CARD_TRACK,
    image: 'trackQuery',
    link: '/about',
  }

  return (
    <div className='HowDoesQuerierWork__container'>
      <div className='HowDoesQuerierWork__title'>How Does Querier Work?</div>
      <div>
        {isDesktop() ? <HomeCard {...assembleProps} textPosition='left'/> : <MobileHomeCard {...assembleProps} />}
        {isDesktop() ? <HomeCard {...buildProps} textPosition='right'/> : <MobileHomeCard {...buildProps} />}
        {isDesktop() ? <HomeCard {...createProps} textPosition='left'/> : <MobileHomeCard {...createProps} />}
        {isDesktop() ? <HomeCard {...submitProps} textPosition='right'/> : <MobileHomeCard {...submitProps} />}
        {isDesktop() ? <HomeCard {...trackProps} textPosition='left'/> : <MobileHomeCard {...trackProps} />}
      </div >
    </div >
  )
}

export default HowDoesQuerierWork;