//==============================================================================
// Internal:
import fetchAPI from "../../utils/fetchAPI";
import { setProjects } from "../slices/projects.slice";
import environment from "../../utils/env";
//==============================================================================

export const getProjectEffect = async (action, { dispatch, getState }) => {
  const projects = await fetchAPI(`${environment.dbBaseURL}/projects`);

  let projectsObj;
  if (projects.length > 0) {
    projectsObj = projects.reduce((accumulator, project) => {
      accumulator[project._id] = project;
      return accumulator;
    }, {});
  }

  dispatch(setProjects(projectsObj));
};