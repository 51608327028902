export const AGENT_DELETE = 'app/deleteAgent';
export const AGENT_CREATE = 'app/createNewAgent'
export const AGENT_UPDATE = 'app/updateAgent';
export const AGENT_UPDATE_PRIORITY = 'app/updateAgentPriority';

export const APP_CREATE_NEW_CUSTOMER       = 'app/createNewCustomer';
export const APP_DELETE_AGENT              = 'app/userAuthorizedDeleteAgent';
export const APP_DELETE_PROJECT            = 'app/userAuthorizedDeleteProject';
export const APP_DELETE_QUERY              = 'app/userAuthorizedDeleteQuery';
export const APP_DELETE_SUBSTITUTION       = 'app/userAuthorizedDeleteSubstitution';
export const APP_DELETE_USER               = 'app/userAuthorizedDeleteUser';
export const APP_DELETE_USER_ACCT          = 'app/deleteUserAccount';
export const APP_GET_PAYMENT_INFO          = 'app/getPaymentInfo';
export const APP_KEEP_AGENT                = 'app/userCancelledDeleteAgent';
export const APP_KEEP_PROJECT              = 'app/userCancelProjectDelete';
export const APP_KEEP_QUERY                = 'app/userCancelledQueryDelete';
export const APP_REDIRECT_IF_TRIAL_EXPIRED = 'app/redirectIfTrialExpired';
export const APP_KEEP_SUBSTITUTION         = 'app/userCancelSubstitutionDelete';
export const APP_KEEP_USER                 = 'app/userCancelUserDelete';
export const APP_HANDLE_IDLE_SESSION       = 'app/handleIdleSession';
export const APP_USER_APPROVE_SEND_EMAIL_TO_AGENT = 'app/userAuthorizedSendEmailToAgent';
export const APP_USER_APPROVE_UPDATE_DRAFT        = 'app/userAuthorizedUpdateGmailDraft';
export const APP_USER_AUTHORIZED_SEND_GMAIL_DRAFT = 'app/userAuthorizesSendGmailDraft';
export const APP_USER_CANCELLED_SEND_GMAIL_DRAFT  = 'app/userCancelsSendGmailDraft';
export const APP_USER_CANCEL_SEND_EMAIL_TO_AGENT  = 'app/userCancelledSendEmailtToAgent';
export const APP_USER_CANCEL_UPDATE_DRAFT         = 'app/userCancelledUpdateGmailDraft';

export const APP_LOGIN  = 'app/login';
export const APP_LOGOUT = 'app/logout';
export const APP_START_SESSION_EXPIRATION = 'app/startSessionExpiration';
export const APP_CONT_LOGOUT_COUNTDOWN    = 'app/continueSessionExpirationCountdown';
export const APP_EXTEND_SESSION           = 'app/extendSessionCountdown';

export const GMAIL_DELETE_DRAFT = 'app/gmailDeleteDraft';
export const GMAIL_GET_THREAD   = 'app/gmailGetDraft';
export const GMAIL_SEND_DRAFT   = 'app/gmailSendDraft';
export const GMAIL_SEND_EMAIL   = 'app/gmailSendEmail';
export const GMAIL_UPDATE_DRAFT = 'app/gmailUpdateDraft';
export const GMAIL_UPDATE_QUERY_SENT_TO_AGENT = 'app/gmailUpdateQuerySentToAgent';

export const INITIALIZE = 'app/initialize';

export const PROJECT_CREATE = 'app/createNewProject';
export const PROJECT_DELETE = 'app/deleteProject';
export const PROJECT_GET    = 'app/fetchProjectData';
export const PROJECTS_GET   = 'app/fetchProjects';
export const PROJECT_UPDATE = 'app/updateProject';

export const QUERY_CREATE_NEW_TEXT = 'app/createNewQueryText';
export const QUERY_CREATE_NEW      = 'app/createNewQuery';
export const QUERY_DELETE          = 'app/deleteQuery';
export const QUERY_UPDATE          = 'app/updateQuery';
export const QUERY_UPDATE_SIMPLE   = 'app/simpleUpdateQuery';
export const QUERY_FINALIZE        = 'app/finalizeQuery';

export const QUERY_TEMPLATE_CREATE_NEW = 'app/createNewQueryTemplate';
export const QUERY_TEMPLATE_UPDATE     = 'app/updateQueryTemplate';

export const QUERY_MANAGER_PERSONAL_LINK = 'app/submitQueryManagerPersonalLink';

export const SUBSTITUTION_CREATE_NEW = 'app/createNewSubstitution';
export const SUBSTITUTION_DELETE     = 'app/deleteSubstitution';
export const SUBSTITUTIONS_GET       = 'app/fetchSubstitutions';
export const SUBSTITUTION_UPDATE     = 'app/updateSubstitution';

export const SUB_ENTRY_CREATE_OR_UPDATE = 'app/createOrUpdateSubstitutionEntries';