//==============================================================================
// External:
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Internal:
import { convertTextToHTML, isDesktop } from '../../../../utils/utils';
//==============================================================================

const AboutStep = ({ text, image, title, id, icon, link }) => {
  return (
    <div className='AboutStep__container' id={id}>
      <div className='AboutStep__upperContainer'>
        {isDesktop() && <FontAwesomeIcon icon={icon} className="AboutStep__icon" />}
        <div className="AboutStep__titleContainer">
          <h2 className='AboutStep__title'>{title}</h2>
          <div className='AboutStep__textContainer'>
            {convertTextToHTML(text)}
          </div>
          <a
            className='AboutStep__learnMoreLink'
            href={link}
            target='_blank'
          >
            Visit Knowledge Center
          </a>
        </div>
      </div>
      <div className='AboutStep__image'>
        <img src={image} />
      </div>
    </div>
  )
}

export default AboutStep;