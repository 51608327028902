//==============================================================================
// External:
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

// Internal:
import ProjectLinks from './ProjectLinks/ProjectLinks';
import AccountUser from '../AccountUser/AccountUser';
import { useNavBarHooks } from '../NavBar/NavBar.hooks';
import {
  useGetProjects,
  useGetQueryTemplate
} from '../../hooks/commonHooks';
import { objHasData } from '../../utils/utils';
//==============================================================================

const MobileNavMenu = ({ setOpen }) => {
  const navigate = useNavigate();
  const [titleState, setTitleState] = useState('');
  const projects = useGetProjects();
  const queryTemplate = useGetQueryTemplate();

  useEffect(() => {
    if (objHasData(projects) && objHasData(queryTemplate)) {
      const project = projects[queryTemplate?.projectId];
      setTitleState(project.title);
    }
  }, [queryTemplate]);

  const {
    isAuthenticatedUser,
    onLogout,
  } = useNavBarHooks();

  const handleProjectClick = ({ title, _id }) => {
    setTitleState(title);
    if (title === 'Project Header') {
      navigate(`/dashboard`);
    } else {
      navigate(`/project-dashboard/${_id}`);
    }
  };

  const handleLinkClick = (route) => {
    navigate(route)
    setOpen(false);
  };

  const projectList = Object.values(projects).map((project) => {
    const { _id, title } = project;
    return (
      <>
        <div
          className='MobileNavMenu__projectTitle'
          onClick={() => handleProjectClick({ title, _id })}
        >
          {title}
        </div>
        {
          title === titleState &&
          <ProjectLinks
            handleLinkClick={handleLinkClick}
            _id={_id}
            queryTemplate={queryTemplate}
          />}
      </>
    )
  });

  return (
    <div className='MobileNavMenu__container'>
      {
        isAuthenticatedUser &&
        <div className='MobileNavMenu__account'>
          <NavLink to='/account' onClick={() => setOpen(false)}>
            <AccountUser/>
          </NavLink>
        </div>
      }
      <div className='MobileNavMenu__authLinks'>
        {
          isAuthenticatedUser ?
            <button onClick={() => onLogout()}>Sign Out</button>
            : <NavLink to="/sign-in" onClick={() => setOpen(false)}>Sign In</NavLink>
        }
      </div>
      <div className='MobileNavMenu__linkGroup'>
        {
          objHasData(projects) &&
          <>
            <div
              className='MobileNavMenu__linkGroupHeader'
              onClick={() => handleProjectClick({ title: 'Project Header', _id: null })}
            >
              Project Links:
            </div>
            <div className='MobileNavMenu__linkGroupList'>
              {projectList}
            </div>
          </>
        }
      </div>
      <div className='MobileNavMenu__linkGroup'>
        <div className='MobileNavMenu__linkGroupHeader'>Site Links:</div>
        <div className='MobileNavMenu__linkGroupList'>
          <NavLink to='/' onClick={() => setOpen(false)}>Home</NavLink>
          <NavLink to='/about' onClick={() => setOpen(false)}>How Querier Works</NavLink>
          <NavLink to='/knowledge-center' onClick={() => setOpen(false)}>Knowledge Center</NavLink>
          <NavLink to='/privacy-policy' onClick={() => setOpen(false)}>Privacy Policy</NavLink>
          <NavLink to='/terms-of-use' onClick={() => setOpen(false)}>Terms of Use</NavLink>
          <NavLink to='/cookie-policy' onClick={() => setOpen(false)}>Cookie Policy</NavLink>
        </div>
      </div>
    </div>
  )
}

export default MobileNavMenu;
