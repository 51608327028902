//==============================================================================
// External:

// Internal:
import { convertTextToHTML } from "../../utils/utils";
//==============================================================================

const formatFinalPages = (finalEmail, agent, chapterOneText, chapterTwoText, chapterThreeText, chapterFourText, chapterFiveText) => {
  let { pageCount, pagesType } = agent || {};

  if (pagesType === 'Words') {
    const words = `${chapterOneText}${chapterTwoText}${chapterThreeText}${chapterFourText}${chapterFiveText}`
    const finalWords = getFinalWords(words, pageCount);
    finalEmail = finalEmail.replace('{{pages}}', finalWords);
  }

  if (pagesType === 'Pages') {
    const pages = `${chapterOneText}${chapterTwoText}${chapterThreeText}${chapterFourText}${chapterFiveText}`
    const finalPages = getFinalWords(pages, pageCount * 250)
    finalEmail = finalEmail.replace('{{pages}}', finalPages);
  }

  if (pagesType === 'Chapter') {
    if (pageCount === 1) {
      finalEmail = finalEmail.replace('{{pages}}', chapterOneText);
    }

    if (pageCount === 2) {
      finalEmail = finalEmail.replace('{{pages}}', `${chapterOneText} ${chapterTwoText}`);
    }

    if (pageCount === 3) {
      finalEmail = finalEmail.replace('{{pages}}', `${chapterOneText} ${chapterTwoText} ${chapterThreeText}`);
    }

    if (pageCount === 4) {
      finalEmail = finalEmail.replace('{{pages}}', `${chapterOneText} ${chapterTwoText} ${chapterThreeText} ${chapterFourText}`);
    }

    if (pageCount === 5) {
      finalEmail = finalEmail.replace('{{pages}}', `${chapterOneText} ${chapterTwoText} ${chapterThreeText} ${chapterFourText} ${chapterFiveText}`);
    }
  }

  return finalEmail
}

const getFinalWords = (words, agengWordCount) => {
  console.log('GET FINAL WORDS: preparing to make final text', {agengWordCount});
  const _isDupParagraph = (para1, para2) => para1.trimStart().trimEnd() === para2.trimStart().trimEnd();
  const _removeHTMLTags = (para) => para.replace(/(<([^>]+)>)/ig, ' ');

  /*
    TEST PLAN:
    - textWithoutHTMLTags.split('   ') below relies on a wide space to be present - what if its not? or different?
      - paragraphs that aren't indented
      - paragraphs that are 'letter' type (ie, multiple indentions)
  */

  const textWithoutHTMLTags = _removeHTMLTags(words);

  // Split into paragraphs without HTML tags, to get pure word count:
  const paragraphsWithoutHTMLTags = textWithoutHTMLTags.split('   ').filter(word => word.trim());

  // Loop through paragraphs woutHTML until the agent's word count is met, rounded to end of nearest paragraph:
  const paragraphsWithoutHTMLTagsCopy = paragraphsWithoutHTMLTags;
  let wordCount = 0;
  paragraphsWithoutHTMLTagsCopy.forEach((para, index, array) => {
    const paraWordLength = para.split(' ').filter(word => word.trim()).length;
    wordCount += paraWordLength; // track word count
    if (wordCount > agengWordCount) {
      array.length = index + 1;  // functions as 'break' statement
    }
  });

  // Return specific paragraph that meets word count:
  const finalParagraph = paragraphsWithoutHTMLTags[paragraphsWithoutHTMLTags.length - 1];

  // Count duplicates of finalParagraph that come before
  // Prevents us from stopping on earlier duplciate paragraph:
  let duplicateFinalParagraphCount = paragraphsWithoutHTMLTagsCopy.filter(paragraph => _isDupParagraph(paragraph, finalParagraph)).length;

  // Find 'finalParagraph' inside the ‘words’ variable (containing HTML tags):
  const paragraphsWithHTMLTags = words.split('</p>').filter(word => word.trim());

  // Return text w tags from beginning up until 'finalParagraph':
  console.log('GET FINAL WORDS: prepping final -----------', {duplicateFinalParagraphCount, finalParagraph});
  paragraphsWithHTMLTags.forEach((para, index, array) => {
    const isDuplicatePara = _isDupParagraph(_removeHTMLTags(para), finalParagraph);
    if (isDuplicatePara) {
      // prevent cutting off writing sample at first duplicate paragraph
      duplicateFinalParagraphCount = duplicateFinalParagraphCount - 1;
    }

    if (isDuplicatePara && duplicateFinalParagraphCount === 0) {
      console.log('GET FINAL WORDS: stopping loop',);
      array.length = index + 1;  // functions as 'break' statement
    }
  });

  // Stitch paragraphs w HTML tags back together again
  // Add back </p> tags bc they were removed on '.split('</p>) above:
  console.log('GET FINAL WORDS: final check ----------', { duplicateFinalParagraphCount, finalParagraph, paraWithLngth: paragraphsWithHTMLTags.length, paraWithout: paragraphsWithoutHTMLTags.length });
  return paragraphsWithHTMLTags.join('</p>');
}

const formatFinalEmail = ({ agent, queryTemplate, newQueryLetterText, substitutionTemplates, substitutionEntriesFS }) => {
  const {
    emailTemplate,
    synopsis,
    chapterOneText,
    chapterTwoText,
    chapterThreeText,
    chapterFourText,
    chapterFiveText
  } = queryTemplate;

  const { queryManager } = agent;

  let finalEmail = '';
  finalEmail = emailTemplate;

  if (agent?.queryLetterRequired && newQueryLetterText) {
    finalEmail = finalEmail.replace('{{queryLetter}}', newQueryLetterText);
  } else {
    finalEmail = finalEmail.replace('{{queryLetter}}', '');
  }

  if (agent?.synopsisRequired && synopsis && !queryManager) {
    finalEmail = finalEmail.replace('{{synopsis}}', synopsis)
  } else {
    finalEmail = finalEmail.replace('{{synopsis}}', '');
    finalEmail = finalEmail.replace('Synopsis:', '');
  }

  if (agent?.pagesType && agent?.pageCount && !queryManager) {
    finalEmail = formatFinalPages(finalEmail, agent, chapterOneText, chapterTwoText, chapterThreeText, chapterFourText, chapterFiveText)
  } else {
    finalEmail = finalEmail.replace('{{pages}}', '');
    finalEmail = finalEmail.replace('Writing Sample:', '');
  }

  // don't loop if there are no substitution entries:
  if (Object.keys(substitutionEntriesFS).length > 0) {
    // Loop through substitutionTemplates and find matching substitutionEntryFS (have same keys)
    Object.values(substitutionTemplates).forEach((substitutionTemplate) => {
      // Replace the substitutionTemplate text with the final input text from substitutionEntriesFS in the finalEmail
      const userHasEnteredSignifier = Boolean(substitutionTemplate.signifier);
      const userHasSubstitutedText = substitutionEntriesFS.hasOwnProperty(substitutionTemplate._id)
      if (userHasEnteredSignifier && userHasSubstitutedText) {
        const replacementText = substitutionEntriesFS[substitutionTemplate._id].finalText;
        finalEmail = finalEmail.replace(substitutionTemplate.signifier, replacementText)
      }
    })
  }

  return { htmlVersion: convertTextToHTML(finalEmail), stringVersion: finalEmail };
}

const roundIndex = (text, pageCount) => {
  const array = text.split(' ').slice(0, pageCount); // pageCount is one greater, but .slice not inclusize

  // check if chosen item has <p></p>
  if (array.at(-1).includes('</p>')) {
    return pageCount;
  }

  // if not, determine if rounding up or down:
  const roundUpArray = text.split(' ').slice(pageCount); //  array from where 'array' left off to end of text
  const roundedUpIndex = roundUpArray.findIndex(word => word.includes('</p>')) + 1;  // find first '</p>', +1 bc zero index
  const roundedDownArray = text.split(' ').slice(0, pageCount - 1) // get array from where 'array' left off back toward beginning
  const roundedDownIndex = roundedDownArray.reverse().findIndex(word => word.includes('<p>')) + 1; // find first '<p>', + 1 bc zero index

  let finalIndex;
  if (roundedUpIndex <= roundedDownIndex) {
    finalIndex = Number(pageCount + roundedUpIndex)
  } else {
    finalIndex = Number(pageCount - roundedDownIndex)
  }

  return finalIndex;
}

const makeFinalArray = (text, index) => {
  const wordsArray = text.split(' ').slice(0, index);
  // remove empty spaces
  const arrayWithoutSpaces = wordsArray.filter(word => word.length > 0)
  let shortenedArray = arrayWithoutSpaces.slice(0, index); // get array of words up until cutoff
  const sanitizedItem = shortenedArray.at(-1).split('<p>')[0]; // remove </p>
  shortenedArray[shortenedArray.length - 1] = sanitizedItem; // replace last item in array
  return shortenedArray;
}

export {
  getFinalWords,
  formatFinalEmail,
  formatFinalPages,
  roundIndex
}