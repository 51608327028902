//===================================================================================
// External:
import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from 'react-router-dom';
import { CookieBanner } from "@schlomoh/react-cookieconsent";
import { useDispatch } from "react-redux";

// Internal:
import About from "../../views/About/About";
import Account from "../../views/Account/Account";
import AgentEdit from "../../views/AgentEdit/AgentEdit";
import AgentNew from "../../views/AgentNew/AgentNew";
import AgentSelect from "../AgentSelect/AgentSelect";
import AgentView from "../../views/AgentView/AgentView";
import Agents from "../../views/Agents/Agents";
import CookiePolicy from "../../views/Misc/CookiePolicy";
import CustomFormReview from "../CustomFormReview/CustomFormReview";
import Dashboard from "../Dashboard/Dashboard";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import FlashMessage from "../FlashMessage/FlashMessage";
import Footer from "../Footer/Footer";
import Home from "../../views/Home/Home";
import IntermediateLanding from "../../views/IntermediateLanding/IntermediateLanding";
import Issue from "../../views/Issue/Issue";
import KnowledgeCenter from "../../views/KnowledgeCenter/KnowledgeCenter";
import Loading from "../Loader/Loading";
import MobileNavBar from "../MobileNavBar/MobileNavBar";
import ModalHandler from "../ModalHandler/ModalHandler";
import NavBar from "../NavBar/NavBar";
import PrivacyPolicy from "../../views/Misc/PrivacyPolicy";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import Project from "../Project/Project";
import QueryBuilder from "../../views/QueryBuilder/QueryBuilder";
import QueryCreate from "../QueryCreate/QueryCreate";
import QueryEdit from "../QueryEdit/QueryEdit";
import QueryFinalReview from "../../views/QueryFinalReview/QueryFinalReview";
import QueryManagerReview from "../../views/QueryManagerReview/QueryManagerReview";
import QueryManagerSubmit from "../../views/QueryManagerSubmit/QueryManagerSubmit";
import QueryTemplate from "../../views/QueryTemplate/QueryTemplate";
import QueryThread from "../../views/QueryThread/QueryThread";
import Sidebar from "../Sidebar/Sidebar";
import SignIn from "../SignIn/SignIn";
import Subscription from "../Subscribe/Subscribe";
import TermsOfUse from "../../views/Misc/TermsOfUse";
import {
  CookieBannerContent,
  CookieManagementContent
} from "../CookieBannerContent/CookieBannerContent";

// Misc
import { useLocationListener } from "../../hooks/useLocationListener";
import { checkPaymentStatusHourly, isDesktop } from "../../utils/utils";
import { handleRedirects, onAcceptCookies, setStyles } from "./AppUtils";
import logrocketInit from "../../utils/logRocket";
import initFontAwesome from "../../utils/initFontAwesome";
//===================================================================================
initFontAwesome();

const App = () => {
  const location = useLocationListener();
  const [shouldShowSidebar, setShouldShowSidebar] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    logrocketInit();
    checkPaymentStatusHourly(dispatch);
  }, [])

  useEffect(() => {
    handleRedirects(location, dispatch, navigate, setShouldShowSidebar);
  }, [location]);

  console.log(`Querier is loaded in the ${process.env.NODE_ENV} environment`);

  return (
    <>
      <ErrorBoundary>
        <div className="AppRouter__container">
          {isDesktop() ? <NavBar /> : <MobileNavBar/>}
          <div
            className="AppRouter__pageContainer"
          >
            <CookieBanner
              acceptButtonText='Accept All'
              declineButtonText='Decline Non-Essential Cookies'
              enableManagement
              infoContent={<CookieBannerContent />}
              buttonWrapperClasses={'left'}
              containerClasses='fooBar'
              managementButtonText='Manage My Preferences'
              managementContent={<CookieManagementContent />}
              onAccept={onAcceptCookies}
              primaryButtonStyle={{ backgroundColor: '#874CD7' }}
            />
            <FlashMessage />
            <ModalHandler />
            <Loading />
            {isDesktop() && shouldShowSidebar && <Sidebar />}
            <div className="AppRouter__contentContainer" style={setStyles(location)}>
              <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/landing' element={<IntermediateLanding />} />
                <Route path='/sign-in' element={<SignIn />} />
                <Route path='/auth/redirect/google' element={<SignIn />} />
                <Route path='/sign-up' element={<SignIn isSignUp />} />
                <Route path='/about' element={<About />} />
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route path='/terms-of-use' element={<TermsOfUse />} />
                <Route path='/cookie-policy' element={<CookiePolicy />} />
                <Route path='/knowledge-center' element={<KnowledgeCenter />} />
                <Route path='/report-issue' element={<Issue />} />

                <Route path='/dashboard' element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                <Route path='/project/:id/agent-edit/:agentId' element={<PrivateRoute><AgentEdit editForm/></PrivateRoute>} />
                <Route path='/project/:id/agent-view/:agentId' element={<PrivateRoute><AgentView/></PrivateRoute>} />
                <Route path='/project/:id/agent-new' element={<PrivateRoute><AgentNew /></PrivateRoute>} />
                <Route path='/project/:id/agent-select/:queryTemplateId' element={<PrivateRoute><AgentSelect /></PrivateRoute>} />
                <Route path='/project/:id/agent/:queryTemplateId' element={<PrivateRoute><AgentNew /></PrivateRoute>} />
                <Route path='/project/:projectId/agents' element={<PrivateRoute><Agents /></PrivateRoute>} />
                <Route path='/project/:id/query-template/:queryTemplateId' element={<PrivateRoute><QueryTemplate /></PrivateRoute>} />
                <Route path='/project-dashboard/:projectId' element={<PrivateRoute><Project /></PrivateRoute>} />
                <Route path='/project/:projectId/queries' element={<PrivateRoute><QueryBuilder /></PrivateRoute>} />
                <Route path='/project/:projectId/query-create' element={<PrivateRoute><QueryCreate /></PrivateRoute>} />
                <Route path='/query-final-review/:queryId' element={<PrivateRoute><QueryFinalReview /></PrivateRoute>} />
                <Route path='/query-thread/:queryId' element={<PrivateRoute><QueryThread /></PrivateRoute>} />
                <Route path='/query-manager-submit/:queryId' element={<PrivateRoute><QueryManagerSubmit /></PrivateRoute>} />
                <Route path='/query-manager-review/:queryId' element={<PrivateRoute><QueryManagerReview /></PrivateRoute>} />
                <Route path='/agent-custom-form-review/:queryId' element={<PrivateRoute><CustomFormReview /></PrivateRoute>} />
                <Route path='/query/:queryId' element={<PrivateRoute><QueryEdit /></PrivateRoute>} />

                <Route path="/subscribe" element={<PrivateRoute><Subscription /></PrivateRoute>} />
                <Route path="/account" element={<PrivateRoute><Account /></PrivateRoute>} />
              </Routes>
            </div>
          </div>
          <Footer />
        </div>
      </ErrorBoundary>
    </>
  );
};

export default App;
