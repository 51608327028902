
//===================================================================================
// External
import React from 'react';

// Internal

//===================================================================================

const SubstitutionEntry = ({
  handleSubstitutionEntriesInput,
  substitution,
  substitutionEntry = {}
}) => {

  const handleTextInput = (event) => {
    handleSubstitutionEntriesInput({
      _id: substitutionEntry._id || substitution._id,
      finalText: event,
      userId: substitutionEntry.userId || substitution.userId,
      projectId: substitutionEntry.projectId || substitution.projectId,
      queryTemplateId: substitutionEntry.queryTemplateId || substitution.queryTemplateId,
      substitutionId: substitutionEntry.substitutionId || substitution._id,
      ...(substitution?.queryId && {queryId: substitution.queryId})
    })
  }

  return (
    <div className={`SubstitutionEntry__container`} key={substitution._id}>
      <div className='SubstitutionEntry__title'>{substitution.title}</div>
      <div className='SubstitutionEntry__signifier'>
        <div className='SubstitutionEntry__label'>Value:</div>
        <textarea
          className='SubstitutionEntry__input'
          onChange={(event) => handleTextInput(event.target.value)}
          placeholder={substitution.signifier}
          value={substitutionEntry.finalText}
        />
      </div>
    </div>
  )
}

export default SubstitutionEntry;