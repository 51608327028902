//==============================================================================
// External:
import React from 'react';
import { Link } from 'react-router-dom';

// Internal:
import { setImage } from '../Home.utils';
//==============================================================================

// Explanatory cards on the main page:
const HomeCard = ({ image, textPosition = 'left', text, title, link }) => {
  return (
    <div className={`HomeCard__container ${textPosition}`}>
      <div className='HomeCard__imageContainer'>
        <img className='HomeCard__image' src={setImage(image)} />
      </div>
      <div className={`HomeCard__contentContainer`}>
        <div className='HomeCard__title'>{title}</div>
        <div className='HomeCard__text'>{text}</div>
        <div className='HomeCard__learnMoreLinkContainer'>
          <Link className='HomeCard__learnMoreLink' to={link}>
            Learn More
          </Link>
        </div>
      </div>
    </div>
  )
}

export default HomeCard;
