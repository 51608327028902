//===================================================================================
// External:
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Internal:
import { logout } from "../../store/actions/actions";
import { getUserDetails, isAuthenticated } from "../../store/slices/user.slice";
//===================================================================================

export const useNavBarHooks = () => {
  const isAuthenticatedUser = useSelector(isAuthenticated);
  const user = useSelector(getUserDetails);
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const onLogout = () => {
    dispatch(logout({ navigate }));
  }

  return {
    dispatch,
    isAuthenticatedUser,
    navigate,
    onLogout,
    user,
  }
}