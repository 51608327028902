//==============================================================================
// Internal:
import environment from "../../utils/env";
import fetchAPI from "../../utils/fetchAPI";
import { setAgents } from "../slices/agents.slice";
import { setFlashMessage, setModalContent } from "../slices/ui.slice";
import { setQueries } from "../slices/queries.slice";
import { makeObjFromArray } from "../../utils/utils";
import * as ACTIONS from '../../utils/actionConstants';
//==============================================================================

export const deleteAgentEffect = async (action, { dispatch, getState, take }) => {
  try {
    const {agentId, navigate, projectId} = action.payload;

    dispatch(setModalContent('deleteAgentConfirmation'));

    const actionsArray = [
      ACTIONS.APP_DELETE_AGENT,
      ACTIONS.APP_KEEP_AGENT,
      'ui/setModalContent'
    ];

    const [newAction, ...rest] = await take(action => actionsArray.includes(action.type));

    dispatch(setModalContent(''));

    const deleteConfirmationAction = ACTIONS.APP_DELETE_AGENT;

    if (newAction.type === deleteConfirmationAction) {
      const url = `${environment.dbBaseURL}/agents/${agentId}`
      const response = await fetchAPI(url, 'DELETE');
      const agentObj = makeObjFromArray(response.agentsArray);
      dispatch(setAgents(agentObj));

      if (response.queryArray.length > 0) {
        const url = `${environment.dbBaseURL}/proj/${projectId}/queries`;
        const queriesArray = await fetchAPI(url);

        if (queriesArray.length > 0) {
          const queryObj = makeObjFromArray(queriesArray);
          dispatch(setQueries(queryObj));
        } else {
          dispatch(setQueries({}));
        }
      }

      // TODO: if query has draft, delete draft;

      dispatch(setFlashMessage({ type: 'success', message: response.message }))
    }

    navigate(`/project/${projectId}/agents`);
  } catch (error) {
    dispatch(setFlashMessage({ type: 'error', message: 'There Was An Error Deleting This Agent. Please Try Again.' }))
    console.error('Error In The DeleteAgentEffect: ', error)
  } finally {

  }
};
