//==============================================================================
// External:
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Internal:
import QueryFormatDropdown from '../Dropdown/QueryFormatDropdown';
import QuestionDisplay from '../QuestionDisplay/QuestionDisplay';
import useAgentFormHooks from './AgentForm.hooks';
import TagsInput from '../TagsInput/TagsInput';
import {
  createNewAgent,
  deleteAgent,
  updateAgent
} from '../../store/actions/actions';
import EmailForm from './Subforms/EmailForm/EmailForm';
import PopoutWindow from '../../assets/window_popout.png'
import QueryManagerForm from './Subforms/QueryManagerForm/QueryManagerForm';
import { handlePageCount, handlePagesType, handleQueryAttr } from './AgentForm.utils';
import {
  checkBooleanHasChanged,
  checkEqualValueOrUntruthy,
  checkTagsEquality,
  makeCheckbox,
  makeInput
} from '../../utils/utils';
//==============================================================================

const AgentForm = ({ editForm = false, formType }) => {
  const {
    agency,
    agencyGuidelines,
    agencySite,
    agent,
    agents,
    agentId,
    dispatch,
    doNotQuery,
    email,
    firstName,
    id,
    isOpen,
    lastName,
    navigate,
    notes,
    pageCount,
    pagesType,
    publishersMarketplace,
    queryFormat,
    queryLetter,
    queryManager,
    rating,
    responseTime,
    socialMedia,
    setAgency,
    setAgencyGuidelines,
    setAgencySite,
    setDoNotQuery,
    setEmail,
    setFirstName,
    setIsOpen,
    setLastName,
    setNotes,
    setPageCount,
    setPagesType,
    setPublishersMarketplace,
    setQueryFormat,
    setQueryLetter,
    setQueryManager,
    setRating,
    setResponseTime,
    setSocialMedia,
    setSynopsis,
    setTags,
    setWishList,
    shouldDisable,
    synopsis,
    tags,
    wishList,
  } = useAgentFormHooks({ editForm });

  const hasChanges = () => {
    if (checkEqualValueOrUntruthy(agent?.firstName, firstName)) return true;
    if (checkEqualValueOrUntruthy(agent?.lastName, lastName)) return true;
    if (checkEqualValueOrUntruthy(agent?.agency, agency)) return true;
    if (checkEqualValueOrUntruthy(agent?.email, email)) return true;
    if (checkEqualValueOrUntruthy(agent?.agencySite, agencySite)) return true;
    if (checkEqualValueOrUntruthy(agent?.agencyGuidelines, agencyGuidelines)) return true;
    if (checkEqualValueOrUntruthy(agent?.socialMedia, socialMedia)) return true;
    if (checkEqualValueOrUntruthy(agent?.publishersMarketplace, publishersMarketplace)) return true;
    if (checkEqualValueOrUntruthy(agent?.queryManager, queryManager)) return true;
    if (checkBooleanHasChanged(String(agent?.isOpen), String(isOpen))) return true;
    if (checkEqualValueOrUntruthy(agent?.rating, rating)) return true;
    if (checkEqualValueOrUntruthy(agent?.responseTime, responseTime)) return true;
    if (checkEqualValueOrUntruthy(agent?.queryFormat, queryFormat)) return true;
    if (checkEqualValueOrUntruthy(agent?.doNotQuery, doNotQuery)) return true;
    if (checkEqualValueOrUntruthy(agent?.queryLetterRequired, queryLetter)) return true;
    if (checkEqualValueOrUntruthy(agent?.synopsisRequired, synopsis)) return true;
    if (checkEqualValueOrUntruthy(agent?.pagesType, pagesType)) return true;
    if (checkEqualValueOrUntruthy(agent?.pageCount, pageCount)) return true;
    if (checkEqualValueOrUntruthy(agent?.notes, notes)) return true;
    if (checkTagsEquality(agent?.tags, tags)) return true;
    return false;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (formType === 'editAgent') {
      dispatch(updateAgent({
        _id: agent._id,
        firstName,
        lastName,
        agency,
        email,
        agencySite,
        agencyGuidelines,
        queryManager,
        socialMedia,
        publishersMarketplace,
        wishList,
        isOpen,
        doNotQuery,
        rating,
        tags,
        responseTime,
        queryLetterRequired: handleQueryAttr(queryFormat, queryLetter),
        queryFormat,
        synopsisRequired: handleQueryAttr(queryFormat, synopsis),
        pagesType: handlePagesType(queryFormat, pagesType),
        pageCount: handlePageCount(queryFormat, pageCount),
        projectId: id,
        navigate,
        notes
      }))
    }
    if (formType === 'newAgent') {
      dispatch(createNewAgent({
        firstName,
        lastName,
        agency,
        email,
        agencySite,
        agencyGuidelines,
        queryManager,
        socialMedia,
        publishersMarketplace,
        wishList,
        isOpen,
        doNotQuery,
        rating,
        responseTime,
        queryFormat,
        queryLetterRequired: handleQueryAttr(queryFormat, queryLetter),
        synopsisRequired: handleQueryAttr(queryFormat, synopsis),
        tags,
        pagesType: handlePagesType(queryFormat, pagesType),
        pageCount: handlePageCount(queryFormat, pageCount),
        navigate,
        notes,
        projectId: id,
        priority: null,
      }))
    }
  }

  const onCancel = () => {
    navigate(-1)
  }

  const onDelete = () => {
    dispatch(deleteAgent({ agentId, navigate, projectId: agent.projectId }))
  }

  const onNavigateToEditAgent = () => {
    window.scrollTo(0, 0);
    navigate(`/project/${agent.projectId}/agent-edit/${agentId}`)
  };

  const emailFormProps = {
    agencyGuidelines,
    email,
    pageCount,
    pagesType,
    queryLetter,
    setAgencyGuidelines,
    setEmail,
    setPageCount,
    setPagesType,
    setQueryLetter,
    setSynopsis,
    shouldDisable,
    synopsis,
  };

  const queryManagerProps = {
    queryManager,
    setQueryManager,
    setPagesType,
    pagesType,
    pageCount,
    setPageCount,
    shouldDisable,
  }


  const displayForm = () => {
    if (queryFormat === 'Email' || queryFormat === 'CustomForm') return <EmailForm emailFormProps={emailFormProps} queryFormat={queryFormat} />;
    if (queryFormat === 'QueryManager') return <QueryManagerForm queryManagerProps={queryManagerProps} />;
    return null;
  }

  return (
    <>
      <form className='AgentForm__form' onSubmit={(event) => handleSubmit(event)} id='newAgentForm'>
        <section >
          <div className='AgentForm__sectionHeader'> Contact Info </div>
          <div className='AgentForm__nameInputContainer'>
            <div className='AgentForm__firstName inputContainer'>
              <div className='AgentForm__label'>Agent Name: </div>
              <div className='AgentForm__nameContainer'>
                {makeInput(setFirstName, firstName, 'nameInput', 'First Name (Required)', {}, shouldDisable)}
                {makeInput(setLastName, lastName, 'nameInput', 'Last Name (Required)', {}, shouldDisable)}
              </div>
            </div>
            <div className='AgentForm__agency inputContainer'>
              <div className='AgentForm__label'>Agency Name: </div>
              {makeInput(setAgency, agency, 'agencyInput', 'Agency Name', {}, shouldDisable)}
            </div>
          </div>
        </section>

        <section>
          <div className='AgentForm__sectionHeader'> Additional Info </div>
          <div className='AgentForm__agencySite inputContainer'>
            <div className='AgentForm__labelPopoutContainer'>
              <div className='AgentForm__label'>Agency Website: </div>
              {
                agencySite &&
                <a href={`${agencySite}`} target="_blank">
                  <img src={PopoutWindow} className='AgentForm__popoutIcon' />
                </a>
              }
            </div>
            {makeInput(setAgencySite, agencySite, 'agencySite', 'Agency Website Address', {}, shouldDisable)}
          </div>
          <div className='AgentForm__queryManager inputContainer'>
            <div className='AgentForm__labelPopoutContainer'>
              <div className='AgentForm__label'>Social Media: </div>
              {
                socialMedia &&
                <a href={`${socialMedia}`} target="_blank">
                  <img src={PopoutWindow} className='AgentForm__popoutIcon' />
                </a>
              }
            </div>
            {makeInput(setSocialMedia, socialMedia, 'socialMedia', 'Social Media Address', {}, shouldDisable)}
          </div>
          <div className='AgentForm__publishersMarketplace inputContainer'>
            <div className='AgentForm__labelPopoutContainer'>
              <div className='AgentForm__label'>Publishers Marketplace: </div>
              {
                publishersMarketplace &&
                <a href={`${publishersMarketplace}`} target="_blank">
                  <img src={PopoutWindow} className='AgentForm__popoutIcon' />
                </a>
              }
            </div>
            {makeInput(
              setPublishersMarketplace,
              publishersMarketplace,
              'publishersMarketplace',
              "Publisher's Marketplace Address",
              {},
              shouldDisable
            )}
          </div>
          <div className='AgentForm__wishList inputContainer'>
            <div className='AgentForm__labelPopoutContainer'>
              <div className='AgentForm__label'>Wish List: </div>
              {
                wishList &&
                <a href={`${wishList}`} target="_blank">
                  <img src={PopoutWindow} className='AgentForm__popoutIcon' />
                </a>
              }
            </div>
            {makeInput(setWishList, wishList, 'wishList', 'WishList Address', {}, shouldDisable)}
          </div>

          <div className='AgentForm__isOpen inputContainer'>
            <div className='AgentForm__label'>Is Open To Queries: </div>
            <label className='AgentForm__radioLabel'>
              <input
                checked={isOpen}
                disabled={shouldDisable}
                onChange={() => setIsOpen(true)}
                type="radio"
                value={true}
              />
              Yes
            </label>
            <label className='AgentForm__radioLabel'>
              <input
                checked={!isOpen}
                disabled={shouldDisable}
                onChange={() => setIsOpen(false)}
                type="radio"
                value={false}
              />
              No
            </label>
            {
              isOpen ?
                <FontAwesomeIcon icon="fa-check-circle" className="AgentCard__checkIcon" />
                : <FontAwesomeIcon icon="fa-exclamation-circle" className="AgentCard__minusIcon" />
            }
          </div>
          <div className='AgentForm__doNotQuery inputContainer'>
            <div className='AgentForm__label'>Do Not Query: </div>
            {makeCheckbox(setDoNotQuery, doNotQuery, 'doNotQuery', shouldDisable)}
            <div className='AgentForm__questionDisplay'>
              <QuestionDisplay tooltipKey={'doNotQuery'} />
            </div>
          </div>
          <div className='AgentForm__rating inputContainer'>
            <div className='AgentForm__label'>Rating: </div>
            {makeInput(
              setRating,
              rating,
              'rating',
              'Rating',
              {
                type: 'text',
                pattern: '[0-9]|10',
                title: "Numeric rating must be 0-10",
              },
              shouldDisable
            )}
            <div className='AgentForm__questionDisplay'>
              <QuestionDisplay tooltipKey={'agentRating'} />
            </div>
          </div>
          <div className='AgentForm__responseTime inputContainer'>
            <div className='AgentForm__label'>Response Time (wks): </div>
            {makeInput(setResponseTime, responseTime, 'responseTime', 'Response Time', {}, shouldDisable)}
            <div className='AgentForm__questionDisplay'>
              <QuestionDisplay tooltipKey={'responseTime'} />
            </div>
          </div>
        </section>

        <section>
          <div className='AgentForm__sectionHeader'>Submission Requirements</div>
          <div className='AgentForm__queryFormat inputContainer'>
            <div className='AgentForm__label'>Query Format: </div>
            <QueryFormatDropdown setValue={setQueryFormat} value={queryFormat} shouldDisable={shouldDisable} />
            <div className='AgentForm__questionDisplay'>
              <QuestionDisplay tooltipKey={'queryFormat'} />
            </div>
          </div>
          <div>
            {displayForm()}
          </div>
        </section>

        <section>
          <div className='AgentForm__sectionHeader'> Query Meta Data: </div>
          <div>
            <div className='AgentForm__label'>Tags:</div>
            <TagsInput
              tags={tags}
              setTags={setTags}
              shouldDisable={shouldDisable}
            />
          </div>
          <div className='AgentForm__comments'>
            <div className='AgentForm__label'>Comments: </div>
            <textarea
              className='AgentForm__textArea'
              disabled={shouldDisable}
              maxLength={250}
              onChange={(event) => setNotes(event.target.value)}
              placeholder='Enter any useful notes about this agent...'
              value={notes}
            />
          </div>
        </section>
      </form >

      <div className='AgentForm__buttonContainer'>
        {
          editForm &&
          <>
            <button
              className='AgentForm__submitButton'
              disabled={!hasChanges()}
              form='newAgentForm'
              type='submit'
            >
              Save Agent Info
            </button>
            <button
              className='AgentForm__cancelButton'
              onClick={() => onCancel()}
              type='button'
            >
              Cancel Changes
            </button>
            <button
              type='button'
              className='AgentForm__deleteButton'
              onClick={() => onDelete()}
            >
              Delete Agent
            </button>
          </>
        }
        {
          !editForm &&
          <button
            className='AgentForm__submitButton'
            onClick={() => onNavigateToEditAgent()}
            type='button'
          >
            Edit Agent
          </button>
        }
      </div>
    </>
  )
}

export default AgentForm;
