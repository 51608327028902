//==============================================================================
// External:
import React from 'react';
import WhyQueryCard from './WhyQuerierCard';

// Internal:

//==============================================================================

const WhyQuerier = ({ }) => {
  return (
    <div className='WhyQuerier__container'>
      <div className='WhyQuerier__title'>
        Why Querier?
      </div>
      <div className='WhyQuerier__contentContainer'>
        <WhyQueryCard
          icon='plan'
          text='Create full query packages matching agent requirements with the click of a button!'
          title='Simplify the query process'
        />
        <WhyQueryCard
          icon='agents'
          text='No more spreadsheets, Word docs, and copying and pasting. Everything you need is one place.'
          title='Make querying less tedious'
        />
        <WhyQueryCard
          icon='query'
          text='Spend your time doing what you love, not querying!'
          title='More writing, less querying'
        />
      </div>
    </div>
  )
}

export default WhyQuerier;