export const MAIN_TEXT = `
<p>Querier will generate custom queries for each agent based on their requirements and make submitting each a snap!</p>
`
export const QUERY_TEMPLATE = `
<p>Add the basic elements of your submission package (query letter, synopsis, writing samples, common QueryManager questions, and more). From these elements, Querier can generate endless query package combinations based on an agent’s submission requirements.</p>
`

export const AGENT_LIST = `
<p>Build your list by adding agents and their submission requirements. Querier will use each agent’s specific requirements to build a customized query. </p>
<p>Collect each agent’s name, agency, submission format (mail, QueryManager, or their own personal form), submission requirements (whether they require query letter, synopsis, or writing sample [words, pages, chapter]), and more, all in one place.</p>
`

export const CURATE_LIST = `
<p>Once your query template and agent list have been built, you’re ready to create your queries. Add any personalizations you like to the query letter, then Querier will create the submission package based on the agent’s requirements.</p>
<p>One agent wants a query letter, synopsis, and the first 10 pages, while another wants a query letter, no synopsis, and the first 8,000 words? No problem. Querier will generate these very different queries with the click of a button.</p>
`

export const CREATE_QUERIES = `
<p>Once your query template and agent list have been built, you’re ready to create your queries. Add any personalizations you like to the query letter, then Querier will create the submission package based on the agent’s requirements.</p>
<p>One agent wants a query letter, synopsis, and the first 10 pages, while another wants a query letter, no synopsis, and the first 8,000 words? No problem. Querier will generate these very different queries with the click of a button.</p>
`

export const SUBMIT_QUERIES = `
<p>Querier makes submitting your package a snap! For email submissions, Querier will send the package and receive replies through your Gmail account. View the entire conversation in Querier!</p>
<p>If the agent uses QueryManager, Querier will post your package directly to the website using the Querier browser extension. </p>
`

export const TRACK_QUERIES = `
<p>Querier allows you to track your submissions with ease! View the entire email thread within Querier or head over to QueryManager with the click of a button. See all your queries in one place, their status, and how much time is left in the agent’s response period. </p>
`