//==============================================================================
// External:
import React from 'react';
import FAQCard from './FAQCard';

// Internal:
import * as FAQ from '../../../utils/constants';
import { convertTextToHTML } from '../../../utils/utils';
//==============================================================================

const FAQs = () => {
  return (
    <div className='FAQs__container'>
      <div className='FAQs__title'>
        Frequently Asked Questions
      </div>
      <div className='FAQs__contentContainer'>
        <FAQCard text={FAQ.QUERY_LIMIT} title='How Many Queries Can I Submit?' />
        <FAQCard text={FAQ.AGENT_LIST} title='How Big Can My Agent List Be?' />
        <FAQCard text={FAQ.QUERIER_COST} title='Does Querier Offer A Free Trial?' customHt={'165px'} />
        <FAQCard text={FAQ.FREE_TRIAL} title='How Much Does Querier Cost?' customHt={'165px'} />
        {/* <FAQCard text={convertTextToHTML(FAQ.TEST)} title='Testing HTML' customHt={'300px'} /> */}
      </div>
    </div>
  )
}

export default FAQs;