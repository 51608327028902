//==============================================================================
// External:
import React from 'react';

// Internal:
//==============================================================================

const KnowledgeCenter = () => {
  return (
    <div className='KnowledgeCenter__container'>
      <iframe src="https://querier.freshdesk.com/support/home" />
    </div>
  )
}

export default KnowledgeCenter;
