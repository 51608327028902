//==============================================================================
// External:
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Internal:

//==============================================================================

const ProjectLinks = ({ handleLinkClick, _id, queryTemplate }) => {
  return (
    <div className='ProjectLinks__container'>
      <div className='ProjectLinks__linkContainer'>
        <FontAwesomeIcon icon="fa-pen-nib" />
        <div
          className='ProjectLinks__link'
          onClick={() => handleLinkClick(`/project/${_id}/query-template/${queryTemplate._id}`)}
        >
          Query Template
        </div>
      </div>
      <div className='ProjectLinks__linkContainer'>
        <FontAwesomeIcon icon="fa-user-tie" />
        <div
          className='ProjectLinks__link'
          onClick={() => handleLinkClick(`/project/${_id}/agents`)}
        >
          Agent List
        </div>
      </div>
      <div className='ProjectLinks__linkContainer'>
        <FontAwesomeIcon icon="fa-clipboard-check" />
        <div
          className='ProjectLinks__link'
          onClick={() => handleLinkClick(`/project/${_id}/queries`)}
        >
          Queries
        </div>
      </div>
    </div>
  )
};

export default ProjectLinks;
