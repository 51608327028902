//==============================================================================
// External:
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Internal:
import { useAgentCardHooks } from '../AgentCard/AgentCard.hooks';
import { rowColor } from '../../utils/utils';
//==============================================================================

const MobileAgentCard = ({
  agent,
  filter,
  parentProps,
  shouldAllowDnD
}) => {
  const {
    navigate,
    projectId,
    queriesByAgentArray,
  } = useAgentCardHooks(agent);

  const {
    _id: agentId,
    agency,
    firstName,
    lastName,
    priority,
  } = agent;

  const {
    isDragged,
    isSelected,
    key,
    ref,
    style,
  } = parentProps;

  const displayPriority = () => {
    return filter === 'agentsToQuery' ? priority : '--'
  }

  const commonTDStyles = (shouldAddWidth = false) => ({
    borderBottom: parentProps.isDragged && 'none',
    borderTop: parentProps.isDragged && 'none',
    ...(shouldAddWidth && { width: '10px' })
  });

  const handleCursor = (isDragged) => {
    if (!shouldAllowDnD) return 'auto';
    return isDragged ? "grabbing" : "grab"
  }

  const handleBackgroundColor = (isDragged, isSelected, key) => {
    if (isDragged) return "#dcdcdc";
    return rowColor(key);
  }

  // see Note1
  const draggingStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '2px dashed black',
    cursor: handleCursor(parentProps.isDragged),
    opacity: 0.5,
  }

  const setAgentName = () => {
    return queriesByAgentArray.includes(agentId)
      ? <strike>{`${firstName} ${lastName}`}</strike>
      : `${firstName} ${lastName}`
  }

  return (
    <div
      ref={ref}
      {...parentProps}
      key={key}
      style={{
        ...style,
        ...(isDragged && { ...draggingStyles }),
        backgroundColor: handleBackgroundColor(isDragged, isSelected, key),
      }}
      className={`MobileAgentCard__container`}
    >
      <div className='MobileAgentCard__upperRow'>
        <div className="MobileAgentCard__gripDotsContainer" style={commonTDStyles()}>
          {shouldAllowDnD && <span><FontAwesomeIcon icon="fa-grip-vertical" className="AgentCard__gripDots" /></span>}
        </div>
        <div style={commonTDStyles(true)} className='MobileAgentCard__priority'>
          {displayPriority()}
        </div>
        <div className='MobileAgentCard__nameAgencyContainer'>
          <div style={commonTDStyles()} className='MobileAgentCard__name'>
            <a
              onClick={() => navigate(`/project/${projectId}/agent-view/${agentId}`)}
              className="AgentCard__link"
            >
              {setAgentName()}
            </a>
          </div>
          <td style={commonTDStyles()} className='MobileAgentCard__agencyName'>
            {agency}
          </td>
        </div>
      </div>
    </div >
  )
}

export default MobileAgentCard;