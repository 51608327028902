// Home Card:
export const HOME_CARD_WRITE  = `Enter the basic elements of your query package: the query letter, synopsis, writing samples, and more. Querier can generate an endless combination of queries from these elements. No more Excel, Word, or copying/pasting!`
export const HOME_CARD_AGENT  = `Input your full agent list along with their contact info, submission requirements, and more. Rate each agent, sort, filter, and prioritize with drag-and-drop ease!`
export const HOME_CARD_BUILD  = `One agent wants a query letter, synopsis, and the first 10 pages, while another wants a query letter, no synopsis, and the first 8,000 words? No problem. Querier will generate these very different submissions with the simple click of a button!`
export const HOME_CARD_SUBMIT = `Querier will link with your Gmail account to send your package to any agent requiring email submissions. For agents using QueryManager, post your query with the click of a button!`
export const HOME_CARD_TRACK  = `Track all your queries in one place. Easily view who you submitted to, when, and whether you’re within their reply period!`

//FAQs:
export const QUERIER_COST = 'Querier offers a 7-day free trial. After that time, a subscription to Querier runs $3.99 a month for unlimited submissions. Need to take a break from querying? Pause your subscription for as long as you like and resume when you’re ready!'
export const QUERY_LIMIT = 'You can send as many queries as you need to find the right agent for your work with a subscription to Querier!'
export const AGENT_LIST = 'There are no limits on the number of agents you can query. Add as many agents to your list as you need!'
export const FREE_TRIAL = 'Querier offers a 7-day free trial. No credit card required. Create your query template, build an agent list, and submit as many queries as you like during that time. If Querier doesn’t simplify the query process for you, quit at any time and owe nothing!'

// Base Texts:
export const BASE_TEXT_CH_ONE = '<p>Add your Chapter One text here!</p>'
export const BASE_TEXT_CH_TWO = '<p>Add your Chapter Two text here!</p>'
export const BASE_TEXT_CH_THREE = '<p>Add your Chapter Three text here!</p>'
export const BASE_TEXT_CH_FOUR = '<p>Add your Chapter Four text here!</p>'
export const BASE_TEXT_CH_FIVE = '<p>Add your Chapter Five text here!</p>'
export const BASE_TEXT_EMAIL_TEMPLATE = '<p>{{queryLetter}}</p><p style="margin-top: 30px">Synopsis:</p><p>{{synopsis}}</p><p style="margin-top: 30px">Pages:</p><p>{{pages}}</p>'
export const BASE_TEXT_EMAIL_SUBJECT = "QUERY - {Your Project Name}"
export const BASE_TEXT_QUERY = '<p>Hello {{name}},</p><p>This is the start of my query letter.</p><p>Regards,</p><p>Me</p>'
export const BASE_TEXT_SYNOPSIS = '<p>Add your synopsis text here!</p>'

// Query Manager:
export const STEP_TEXT_AGENT_LIST = 'Agent List Step Text will do here. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
export const STEP_TEXT_CHAPTER = `Add up to five chapters of your work below. Querier will use these chapters to calculate the correct writing sample to include in your submission. \n Do your best with formatting, but don't worry if you can't get it exactly right. Online formatting is notoriously difficult, and agents are understanding.\nLastly, be sure to include chapter headerings (ie, "Chapter One")!`
export const STEP_TEXT_EMAIL = 'The default subject line of your query emails. Email subjects can be customized later if the agent requires a custom subject line, otherwise Querier will use this default.'
export const STEP_TEXT_QUERY_TEMPLATE = `
Create your query letter template below.
\nText substitutions will allow you to personalize your letter when you create each actual query.
`
export const STEP_TEXT_SYNOPSIS = "Add your synopsis below. Querier will add your synopsis when the agent's requirements call for it!"
export const STEP_TEXT_QUERYMANAGER = 'Add information about your project Querier can to fill out QueryManager forms!'

// Query Builder/Create:
// export const STEP_TEXT_SELECT_AGENT = 'If not already selected, choose the agent you wish to query in the dropdown below.'
export const STEP_TEXT_ADD_SUBSTITUTIONS = 'Query Create: add substitutions. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
export const STEP_TEXT_CUSTOM_EMAIL = 'If the agent requires a custom email subject, alter the default subject below. If they agent does not require a custom email subject, Querier will use the default subject shown  below.'
export const STEP_TEXT_QUERY_LTR = 'Complete your query letter by adding any text substitutions. Review the query letter in the "Review Query Letter" section.'
export const STEP_TEXT_SELECT_VERSION = 'Query Create: select version. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
export const STEP_TEXT_REVIEW_QUERY = 'Query Create: review query. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
export const STEP_TEXT_SEND_QUERY_INBOX = 'Query Create: send query to inbox. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
export const STEP_TEXT_SEND_QUERY_AGENT = 'Query Create: send query to agent. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'

// Query Manager Submit:
export const QM_SUBMIT_TEXT = 'QueryManager submit text'
export const QM_PERSONAL_LINK = 'QueryManager personal link text'

// Gmail Errors:
export const GMAIL_THREAD_NOT_FOUND = `We apologize for the inconvenience, but Google could not find this email thread. \nPlease return to your Gmail account to read these emails.`
export const GMAIL_TOKEN_EXPIRED = `Your Google credentials have expired. Please log back in to Querier to obtain new Google credentials.`



export const TEST = `
<p>Build and send queries with three simple steps:</p>
<ul>
<ul>
<ul>
<li>
<p>Make Your Query Template: Create a query template with placeholders for customized text.</p>
</li>
<li>
<p>Create Your Agent List: Store a list of agents</p>
</li>
</ul>
</ul>
</ul>
`
