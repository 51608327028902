//==============================================================================
// External:
import React from 'react';
import { useNavigate } from 'react-router-dom';

// Internal:
import QuillImage from "../../assets/quill-black.svg";
//==============================================================================

const BetaAnnouncement = ({ }) => {
  const navigate = useNavigate();

  return (
    <div className='BetaAnnouncement__container'>
      {/* <div className='BetaAnnouncement__logoContainer'>
        <img src={QuillImage} className='BetaAnnouncement__logo' />
      </div>
      <div className='BetaAnnouncement__title'>Welcome to Querier!</div> */}
      <div className='BetaAnnouncement__text'>
        <p>We're in <b>Beta testing mode</b>, which means some things may not always work as intended.</p>
        <p>Please help us make Querier better by clicking the button below to report any issues you encounter.</p>
        <p>Now, let's get querying!</p>
      </div>
      <div className="BetaAnnouncement__buttonContainer">
        <button
          className='BetaAnnouncement__button'
          onClick={() => navigate('/report-issue')}
        >
          Leave Feedback / Report Issue
        </button>
      </div>
    </div>
  )
}

export default BetaAnnouncement;