//==============================================================================
// External:
import React from 'react';
import { useNavigate } from 'react-router-dom';

// Internal:

//==============================================================================

export const HeroSection = ({imageHeight}) => {
  const navigate = useNavigate();

  return (
    <div className="HeroSection__container" style={{ height: imageHeight }}>
      <div className="HeroSection__textInnerContainer">
        <div className="HeroSection__textLine">
          Querying Sucks.
        </div>
        <div className="HeroSection__textLine">
          Querier Can Make It Suck Less.
        </div>
        <button onClick={() => navigate('/sign-up')}>
          Start Querying Today
        </button>
      </div>
    </div>
  )
}

export default HeroSection;
