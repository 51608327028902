//==============================================================================
// External:
import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Hamburger from 'hamburger-react'

// Internal:
import QuillImage from "../../assets/quill.svg";
import MobileNavMenu from "../MobileNavMenu/MobileNavMenu";
import { useLocationListener } from "../../hooks/useLocationListener";
//==============================================================================

const MobileNavBar = ({ }) => {
  const [isOpen, setOpen] = useState(false);
  const location = useLocationListener()
  const navigate = useNavigate();

  useEffect(() => {
    const whitelist = []
  }, [location])

  return (
    <div className='MobileNavBar__container'>
      <div className='MobileNavBar__innerContainer'>
        <div className='MobileNavBar__title' onClick={() => navigate('/')}>
          <img src={QuillImage} className="MobileNavBar__icon" />
          Querier
        </div>
        <Hamburger toggled={isOpen} toggle={setOpen} color="white"/>
      </div>
      {isOpen && <MobileNavMenu setOpen={setOpen}/>}
    </div>
  )
}

export default MobileNavBar;
