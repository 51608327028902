//==============================================================================
// External:
import React from 'react';

// Internal:

//==============================================================================

const AboutAside = ({titles}) => {

  const AsideStep = ({number, name, id}) => {
    return (
      <a className='AboutAside__step' href={`#${id}`}>
        <div className='AboutAside__stepNumber'>{number}</div>
        <div className='AboutAside__stepName'>{name}</div>
      </a>
    )
  }

  return (
    <div className='AboutAside__container'>
      <AsideStep number={1} name={titles.queryTemplate}   id='QueryTemplate'/>
      <AsideStep number={2} name={titles.agentList}       id='AgentList'/>
      <AsideStep number={3} name={titles.curateList}      id='CurateList'/>
      <AsideStep number={4} name={titles.createQueries}   id='CreateQuery'/>
      <AsideStep number={5} name={titles.submit}          id='SubmitQuery'/>
      <AsideStep number={6} name={titles.track}           id='TrackQuery'/>
    </div>
  )
}

export default AboutAside;