//==============================================================================
// Internal:
import fetchAPI from "../../utils/fetchAPI";
import environment from "../../utils/env";
//==============================================================================

export const gmailRenewAuthToken = async (action, { dispatch, getState }) => {
  try {
    dispatch({type: 'START_RENEW_AUTH_TOKEN_EFFECT'});
    await fetchAPI(`${environment.dbBaseURL}/mail/renew-credentials`);
    dispatch({type: 'END_RENEW_AUTH_TOKEN_EFFECT'});
  } catch (error) {
    console.error('Error in GmailRenewAuthToken Effect:', error);
  }
};