//==============================================================================
// External:
import React from 'react';

// Internal:
import AgentForm from '../../components/AgentForm/AgentForm';
import PageSubtitle from '../../components/PageSubtitle/PageSubtitle';
//==============================================================================

const AgentView = () => {
  return (
    <div className='AgentView__container' >
      <PageSubtitle
        title='View Agent'
      />
      <div className='AgentView__formContainer'>
        <AgentForm />
      </div>
    </div>
  )
}

export default AgentView;