//==============================================================================
// External
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Internal
import CountdownModal from '../CountdownModal/CountdownModal';
import DeleteAgentConfirmationModal from '../DeleteAgentConfirmationModal/DeleteAgentConfirmation.modal';
import DeleteProjectConfirmationModal from '../DeleteProjectConfirmationModal/DeleteProjectConfirmation.modal';
import DeleteQueryConfirmationModal from '../DeleteQueryConfirmationModal/DeleteQueryConfirmation.modal';
import DeleteSubstitutionConfirmationModal from '../DeleteSubstitutionConfirmationModal/DeleteSubstitutionConfirmation.modal';
import DeleteUserConfirmationModal from '../DeleteUserConfirmationModal/DeleteUserConfirmation.modal';
import GmailUpdateDraftModal from '../GmailUpdateDraftModal/GmailUpdateDraftModal';
import GoogleSendEmailToAgentModal from '../GoogleSendEmailToAgentModal/GoogleSendEmailToAgentModal';
import GoogleSendModal from '../GoogleSendModal/GoogleSendModal';
import ProjectCreate from '../ProjectCreate/ProjectCreate';
import ProjectEdit from '../ProjectEdit/ProjectEdit';
import SubstitutionsCreate from '../SubstitutionFinalText/SubstitutionFinalText';
import { getShowModal } from '../../store/slices/ui.slice';
//==============================================================================

const useModalHandlerHooks = () => {
  const modalName = useSelector(getShowModal);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const [error, setError] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    let modal = modalContentRegistry(modalName);
    setModalContent(modal.component);
    setModalTitle(modal.title);
  }, [modalName]);

  const props = {
    error,
    setError
  }

  const modalContentRegistry = (modal) => {
    switch (modal) {
      case 'countdownModal':
        return {
          component: <CountdownModal />,
          title: 'Session Timeout'
        }
      case 'createNewProject':
        return {
          component: <ProjectCreate {...props} />,
          title: 'Create New Project'
        }
      case 'createSubstitution':
        return {
          component: <SubstitutionsCreate />,
          title: 'Create Substitution'
        }
      case 'deleteAgentConfirmation':
        return {
          component: <DeleteAgentConfirmationModal {...props} />,
          title: 'Confirm You With To Delete Agent'
        }
      case 'deleteProjectConfirmation':
        return {
          component: <DeleteProjectConfirmationModal />,
          title: 'Confirm You Wish To Delete Query'
        }
      case 'deleteQueryConfirmation':
        return {
          component: <DeleteQueryConfirmationModal />,
          title: 'Confirm You Wish To Delete Query'
        }
      case 'deleteSubstitutionConfirmation':
        return {
          component: <DeleteSubstitutionConfirmationModal />,
          title: 'Confirm You Wish To Delete Substitution'
        }
      case 'deleteUserConfirmation':
        return {
          component: <DeleteUserConfirmationModal />,
          title: 'Confirm You Wish To Delete Profile'
        }
      case 'editProject':
        return {
          component: <ProjectEdit {...props} />,
          title: 'Edit Project'
        }
      case 'googleSendConfirmation':
        return {
          component: <GoogleSendModal />,
          title: 'Send To Your Gmail Inbox?'
        }
      case 'googleSendEmailToAgentConfirmation':
        return {
          component: <GoogleSendEmailToAgentModal />,
          title: 'Send Email To Agent Confirmation'
        }
      case 'gmailUpdateDraft':
        return {
          component: <GmailUpdateDraftModal />,
          title: 'Update Gmail Draft?'
        }

      default:
        return '';
    }
  };

  return {
    dispatch,
    error,
    modalContent,
    modalTitle,
    setError,
  };
};

export default useModalHandlerHooks;
