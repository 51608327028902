//==============================================================================
// External:
import React from 'react';
import { useDispatch } from 'react-redux';

// Internal:
import {
  APP_DELETE_AGENT,
  APP_KEEP_AGENT,
} from '../../utils/actionConstants';
//==============================================================================

const DeleteAgentConfirmationModal = () => {
  const dispatch = useDispatch();

  const onDelete = () => {
    dispatch({type: APP_DELETE_AGENT})
  }

  const onClose = () => {
    dispatch({type: APP_KEEP_AGENT});
  }

  return (
    <div className='DeleteAgenttConfirmationModal__container'>
      <div className='DeleteAgenttConfirmationModal__textContainer'>
        <div>Are you sure you want to delete this agent?</div>
        <div>Any associated queries will also be deleted.</div>
        <div>Once they've been deleted, they can't be recovered.</div>
      </div>
      <div className='DeleteAgenttConfirmationModal__buttonContainer'>
        <button
          className='DeleteAgenttConfirmationModal__keepButton'
          onClick={() => onClose()}
        >
          Keep Agent
        </button>
        <button
          className='DeleteAgenttConfirmationModal__deleteButton'
          onClick={() => onDelete()}
        >
          Delete Agent
        </button>
      </div>
    </div>
  )
}

export default DeleteAgentConfirmationModal;