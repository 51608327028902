//==============================================================================
// External:
import React from 'react';

// Internal:
import QueryMgrGenreDropdown from '../Dropdown/QueryManagerGenres';
//==============================================================================

const QueryManagerTemplate = ({ props }) => {
  const {
    authorBlog,
    authorTwitter,
    authorWebsite,
    biography,
    bookPreviouslyPublished,
    genre,
    hasBeenPreviouslyPublished,
    hasBeenPreviouslyRepd,
    phoneNumber,
    pitch,
    setAuthorBlog,
    setAuthorTwitter,
    setAuthorWebsite,
    setBiography,
    setBookPreviouslyPublished,
    setGenre,
    setHasBeenPreviouslyPublished,
    setHasBeenPreviouslyRepd,
    setPhoneNumber,
    setPitch,
    setSimilarBooks,
    setTargetAudience,
    setBookTitle,
    setWordCount,
    similarBooks,
    targetAudience,
    bookTitle,
    wordCount,
  } = props;


  return (
    <div className='QueryTitle__container'>
      <div className='QueryTitle__form' >
        <div className='QueryTitle__queryTitle'>
          <label>Title:</label>
          <input
            id='Title'
            onChange={(event) => setBookTitle(event.target.value)}
            placeholder={"Enter book's title here..."}
            value={bookTitle}
          />
        </div>
        <div className='QueryTitle__queryTitle'>
          <label>Author's Biography:</label>
          <textarea
            id='Biography'
            onChange={(event) => setBiography(event.target.value)}
            placeholder={'Enter your biography here...'}
            value={biography}
          />
        </div>
        <div className='QueryTitle__queryTitle'>
          <label>Phone Number:</label>
          <input
            id='PhoneNumber'
            onChange={(event) => setPhoneNumber(event.target.value)}
            placeholder={'Enter your phone number here...'}
            value={phoneNumber}
          />
        </div>

        <div className='QueryTitle__queryTitle'>
          <label>Author's Website:</label>
          <input
            id='AuthorWebAddress'
            onChange={(event) => setAuthorWebsite(event.target.value)}
            placeholder={'Enter your website address here...'}
            value={authorWebsite}
          />
        </div>
        <div className='QueryTitle__queryTitle'>
          <label>Author's Blog:</label>
          <input
            id='AuthorBlogAddress'
            onChange={(event) => setAuthorBlog(event.target.value)}
            placeholder={'Enter your blog address here...'}
            value={authorBlog}
          />
        </div>
        <div className='QueryTitle__queryTitle'>
          <label>Author's Twitter (X):</label>
          <input
            id='AuthorTwitter'
            onChange={(event) => setAuthorTwitter(event.target.value)}
            placeholder={'Enter your Twitter (X) address here...'}
            value={authorTwitter}
          />
        </div>
        <div className='QueryTitle__queryTitle'>
          <label>Genre:</label>
          <QueryMgrGenreDropdown value={genre} setValue={setGenre} />
        </div>
        <div className='QueryTitle__queryTitle'>
          <label>Word Count:</label>
          <input
            id='WordCount'
            onChange={(event) => setWordCount(event.target.value)}
            placeholder={'Enter word count here...'}
            value={wordCount}
          />
        </div>


        <div className='QueryTitle__queryTitle'>
          <label>Pitch:</label>
          <textarea
            id='Pitch'
            onChange={(event) => setPitch(event.target.value)}
            placeholder={'Enter your pitch here...'}
            value={pitch}
          />
        </div>
        <div className='QueryTitle__queryTitle'>
          <label>Target Audience:</label>
          <textarea
            id='TargetAudience'
            onChange={(event) => setTargetAudience(event.target.value)}
            placeholder={'Enter your target audience here...'}
            value={targetAudience}
          />
        </div>
        <div className='QueryTitle__queryTitle'>
          <label>Similar Books:</label>
          <textarea
            id='SimilarBooks'
            onChange={(event) => setSimilarBooks(event.target.value)}
            placeholder={'Enter list of similar books here...'}
            value={similarBooks}
          />
        </div>
        <div className='QueryTitle__queryTitle'>
          <label>Has this book been published before?</label>
          <label className='QueryTitle__radioLabel'>
            <input
              type="radio"
              value={true}
              checked={bookPreviouslyPublished === true}
              onChange={() => setBookPreviouslyPublished(true)}
            />
            Yes
          </label>
          <label className='QueryTitle__radioLabel'>
            <input
              type="radio"
              value={false}
              checked={bookPreviouslyPublished === false}
              onChange={() => setBookPreviouslyPublished(false)}
            />
            No
          </label>
        </div>
        <div className='QueryTitle__queryTitle'>
          <label>Have you previously published other books?</label>
          <label className='QueryTitle__radioLabel'>
            <input
              type="radio"
              value={true}
              checked={hasBeenPreviouslyPublished === true}
              onChange={() => setHasBeenPreviouslyPublished(true)}
            />
            Yes
          </label>
          <label className='QueryTitle__radioLabel'>
            <input
              type="radio"
              value={false}
              checked={hasBeenPreviouslyPublished === false}
              onChange={() => setHasBeenPreviouslyPublished(false)}
            />
            No
          </label>
        </div>
        <div className='QueryTitle__queryTitle'>
          <label>Have you been represented by a literary agent before?</label>
          <label className='QueryTitle__radioLabel'>
            <input
              type="radio"
              value={true}
              checked={hasBeenPreviouslyRepd === true}
              onChange={() => setHasBeenPreviouslyRepd(true)}
            />
            Yes
          </label>
          <label className='QueryTitle__radioLabel'>
            <input
              type="radio"
              value={false}
              checked={hasBeenPreviouslyRepd === false}
              onChange={() => setHasBeenPreviouslyRepd(false)}
            />
            No
          </label>
        </div>
      </div>
    </div>
  )
}

export default QueryManagerTemplate;