//===================================================================================
// Internal:
import { redirectIfTrialPeriodExpired } from "../../store/actions/actions";
import { isMobile } from "../../utils/utils";
//===================================================================================

export const handleRedirects = (location, dispatch, navigate, setShouldShowSidebar) => {
  const whitelistedURLs = ['/about', '/knowledge-center', '/privacy-policy', '/terms-of-use', '/sign-in', '/sign-up', '/cookie-policy']; //safe for all

  // determine whether to show sidebar or not:
  const sidebarWhitelistedURLs = ['/', ...whitelistedURLs];
  const shouldShowSidebar = !sidebarWhitelistedURLs.includes(location) && location !== null;
  if (shouldShowSidebar) {
    setShouldShowSidebar(true);
  } else {
    setShouldShowSidebar(false);
  }

  // redirect based on subscription status:
  let shouldCheckSubscription = false;
  if (location) {
    const urlsNotNeedingSubscription = ['/payment', '/account', '/cancel', '/subscribe', '/dashboard', '/agents', '/query-template/', '/queries', 'project-dashboard', ...whitelistedURLs]
    shouldCheckSubscription = !urlsNotNeedingSubscription.some(safeURL => {
      if (location === '/') return true;
      return location?.includes(safeURL)
    }); // url is not in array
  }

  if (shouldCheckSubscription) {
    dispatch(redirectIfTrialPeriodExpired({ navigate }));
  }
};

export const onAcceptCookies = () => {
  const cookie = document.cookie;
  const newCookie = `${cookie};expires=${new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toUTCString()}`; //1 year out
  document.cookie = newCookie;
};

export const setStyles = (location) => {
  const blacklistedURLs = ['/', '/sign-in', '/sign-up', null]
  if (isMobile()) blacklistedURLs.push('/knowledge-center')
  return {
    padding: !blacklistedURLs.includes(location) ? '25px' : ''
  }
};

