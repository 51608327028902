//==============================================================================
// External:
import React from 'react';
import { useDispatch } from 'react-redux';

// Internal:
import {
  APP_DELETE_SUBSTITUTION,
  APP_KEEP_SUBSTITUTION
} from '../../utils/actionConstants';
//==============================================================================

const DeleteSubstitutionConfirmationModal = ({ }) => {
  const dispatch = useDispatch();

  const onDelete = () => {
    dispatch({type: APP_DELETE_SUBSTITUTION})
  }

  const onClose = () => {
    dispatch({type: APP_KEEP_SUBSTITUTION})
  }

  return (
    <div className='DeleteSubstitutionConfirmationModal__container'>
      <div className='DeleteSubstitutionConfirmationModal__textContainer'>
        <div>Are you sure you want to delete this substitution?</div>
        <div>Once it's been deleted, it can't be recovered.</div>
        <div className=''>{'Be Sure To Delete the Substitution Text From Your Query (e.g., {{yourText}})!'}</div>
      </div>
      <div className='DeleteSubstitutionConfirmationModal__buttonContainer'>
        <button
          className='DeleteSubstitutionConfirmationModal__keepButton'
          onClick={() => onClose()}
        >
          Keep Substitution
        </button>
        <button
          className='DeleteSubstitutionConfirmationModal__deleteButton'
          onClick={() => onDelete()}
        >
          Delete Substitution
        </button>
      </div>
    </div>
  )
}

export default DeleteSubstitutionConfirmationModal;