//==============================================================================
// External:
import React from 'react';

// Internal:
import { displayIcon, getChecklistProgress } from './QueryTemplateStatus.utils';
import {
  useGetQueryTemplate,
  useGetSubstitutions
} from '../../../../hooks/commonHooks';
//==============================================================================

const QueryTemplateStatus = ({ }) => {
  const queryTemplate = useGetQueryTemplate();
  const substitutions = useGetSubstitutions();
  const progress = getChecklistProgress(queryTemplate, substitutions);
  // const hasQueryNameAndEmailSubj = progress.hasEmailSubject && progress.hasTitle;
  const hasQueryNameAndEmailSubj = progress.hasEmailSubject;

  const QueryNameAndEmailSubj = displayIcon(hasQueryNameAndEmailSubj, 'Email Subj.');
  const QueryLetter = displayIcon(progress.hasQueryText, 'Query Letter');
  const Synopsis = displayIcon(progress.hasSynopsis, 'Synopsis');
  const Chapters = displayIcon(progress.hasChapters, 'Sample Text');
  const QueryManager = displayIcon(progress.hasCompletedQueryMgr, 'Query Manager');

  return (
    <div className='QueryTemplateStatus__container'>
      <div className='QueryTemplateStatus__title statusTitle'>

      </div>
      <div className='QueryTemplateStatus__iconRow'>
        <div className='QueryTemplateStatus__icon'>
          {QueryNameAndEmailSubj}
        </div>
        <div className='QueryTemplateStatus__icon'>
          {QueryLetter}
        </div>
        <div className='QueryTemplateStatus__icon'>
          {Synopsis}
        </div>
        <div className='QueryTemplateStatus__icon'>
          {Chapters}
        </div>
        <div className='QueryTemplateStatus__icon'>
          {QueryManager}
        </div>
      </div>
    </div>
  )
}

export default QueryTemplateStatus;
