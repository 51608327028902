//==============================================================================
// External:
import React from 'react';
import { Link } from 'react-router-dom';

// Internal:
import { setImage } from '../Home.utils';
//==============================================================================

const MobileHomeCard = ({ image, text, title, link }) => {
  return (
    <div className='MobileHomeCard__container'>
      <div className='MobileHomeCard__contentContainer'>
        <div className='MobileHomeCard__title'>{title}</div>
        <div className='MobileHomeCard__text'>{text}</div>
        <div className='MobileHomeCard__learnMoreLinkContainer'>
          <Link className='MobileHomeCard__learnMoreLink' to={link}>
            Learn More
          </Link>
        </div>
      </div>
      <div className='MobileHomeCard__imageContainer'>
        <img className='MobileHomeCard__image' src={setImage(image)} />
      </div>
    </div>
  )
}

export default MobileHomeCard;