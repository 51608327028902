//==============================================================================
// External:
import React, { useEffect } from 'react';

// Internal:
import AboutStep from './Subcomponents/AboutStep/AboutStep';
import AboutAside from './Subcomponents/AboutAside/AboutAside';
import * as TEXT from './About.text';
//image
import QueryTemplate from "../../assets/home_1.png";
import AgentList from "../../assets/home_2.png";
import CreateQuery from "../../assets/home_3.png";
import SubmitQuery from "../../assets/home_4.png";
import TrackQuery from "../../assets/home_5.png";
import AddAgent from "../../assets/home_6.png";
import { convertTextToHTML, isDesktop } from '../../utils/utils';
//==============================================================================

// see Design Examples below

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const titles = {
    queryTemplate: 'Make Query Template',
    agentList: 'Build Agent List',
    curateList: 'Curate Agent List',
    createQueries: 'Create Queries',
    submit: 'Submit',
    track: 'Track'
  }

  return (
    <div className='About__container'>
      <main>
        <h1>How Querier Works</h1>
        <div className='About__mainText'>{convertTextToHTML(TEXT.MAIN_TEXT)}</div>
        <a
          className='About__knowledgeCenterBtn'
          href='https://querier.freshdesk.com/support/solutions'
          target='_blank'
        >
          <button>Querier Knowledge Center</button>
        </a>
      </main>
      <section>
        {
          isDesktop() &&
          <aside>
            <AboutAside titles={titles} />
          </aside>
        }
        <div className='About__stepsContainer'>
          <AboutStep
            text={TEXT.QUERY_TEMPLATE}
            title={titles.queryTemplate}
            id={'QueryTemplate'}
            icon='fa-list-ol'
            link={'https://querier.freshdesk.com/support/solutions/157000121934'}
            image={QueryTemplate}
          />
          <AboutStep
            text={TEXT.AGENT_LIST}
            image={AddAgent}
            title={titles.agentList}
            id={'AgentList'}
            icon='fa-user'
            link={'https://querier.freshdesk.com/support/solutions/157000121890'}
          />
          <AboutStep
            text={TEXT.CURATE_LIST}
            image={AgentList}
            title={titles.curateList}
            id={'CurateList'}
            icon='fa-users'
            link={'https://querier.freshdesk.com/support/solutions/157000121890'}
          />
          <AboutStep
            text={TEXT.CREATE_QUERIES}
            image={CreateQuery}
            title={titles.createQueries}
            id={'CreateQuery'}
            icon='fa-envelope-open-text'
            link={'https://querier.freshdesk.com/support/solutions/157000107821'}
          />
          <AboutStep
            text={TEXT.SUBMIT_QUERIES}
            image={SubmitQuery}
            title={titles.submit}
            id={'SubmitQuery'}
            icon='fa-paper-plane'
            link={'https://querier.freshdesk.com/support/solutions/157000107822'}
          />
          <AboutStep
            text={TEXT.TRACK_QUERIES}
            image={TrackQuery}
            title={titles.track}
            id={'TrackQuery'}
            icon='fa-chart-line'
            link={'https://querier.freshdesk.com/support/solutions/157000107824'}
          />
        </div>
      </section>
    </div>
  )
}

export default About;

/*
Design Examples:
https://www.atlassian.com/software/jira/service-management/customer-service-software
https://rise.com/how-rise-works
https://www.wineinvestment.com/how-it-works/investing-with-us/
https://pathedits.com/pages/how-it-works
https://www.makipeople.com/features#review-results
*/
