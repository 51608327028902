//==============================================================================
// External:
import React from 'react';
import { useGetUser } from '../../hooks/commonHooks';

// Internal:

//==============================================================================

const AccountUser = () => {
  const user = useGetUser();

  return (
    <div className='AccountUser__container'>
      <div className='AccountUser__picture'>
        <img src={user.picture} />
      </div>
      <div className='AccountUser__userInfoContainer'>
        <div className='AccountUser__info bold'>{`${user.firstName} ${user.lastName}`}</div>
        <div className='AccountUser__info'>{`${user.email}`}</div>
      </div>
    </div>
  )
}

export default AccountUser;