//==============================================================================
// External:
import React from 'react';
import { List, arrayMove } from "react-movable";

// Internal:
import AgentCard from '../AgentCard/AgentCard';
import { isDesktop } from '../../utils/utils';
import MobileAgentCard from '../MobileAgentCard/MobileAgentCard';
//==============================================================================

const AgentList = ({
  agentsList,
  filter,
  onDrop,
  shouldAllowDnD,
}) => {

  const AgentCardWrapper = React.forwardRef(({ value, parentProps, isDragged }, ref) => {
    parentProps.isDragged = isDragged;
    const finalCard = isDesktop() ?
      <AgentCard
        agent={value}
        filter={filter}
        parentProps={parentProps}
        shouldAllowDnD={shouldAllowDnD}
      />
      : <MobileAgentCard
        agent={value}
        filter={filter}
        parentProps={parentProps}
        shouldAllowDnD={shouldAllowDnD}
      />
    return finalCard
  });

  const makeRenderList = ({ children, props, isDragged }) => {
    const desktopRow = (
      <table className='AgentList__table'>
        <thead>
          <tr className='AgentList__tableRowHeader'>
            <th className='AgentList__gripDotsContainer'>.</th> {/* See note1 */}
            <th>Priority</th>
            <th>Agent Name</th>
            <th>Agency</th>
            <th>Rating</th>
            <th>Tags</th>
            <th>Query Type</th>
            <th>Is Open</th>
            <th>Query Agent</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody {...props}>{children}</tbody>
      </table>
    );

    const mobileRow = <div {...props}>{children}</div>

    return isDesktop() ? desktopRow : mobileRow;
  }

  return (
    <div className='AgentList__container'>
      <List
        disabled={!shouldAllowDnD}
        lockVertically
        values={agentsList || []}
        onChange={({ oldIndex, newIndex }) => {
          onDrop(arrayMove(agentsList, oldIndex, newIndex));
        }}
        renderList={({ children, props, isDragged }) => {
          return makeRenderList({ children, props, isDragged });
        }}
        renderItem={({ value, props, isDragged }) => {
          if (agentsList.length < 1) return []
          return <AgentCardWrapper value={value} parentProps={props} isDragged={isDragged} />
        }}
      />
    </div>
  )
}

export default AgentList;

/*
NOTES:
1. This line was throwing a ' Whitespace text nodes cannot appear as a child of <tr>'
   error. This elem holds the grip dots.
*/
