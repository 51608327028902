//==============================================================================
// Internal:
import { setFlashMessage, setModalContent } from "../slices/ui.slice";
import { clearUserDetails } from "../slices/user.slice";
import { APP_DELETE_USER, APP_KEEP_USER } from "../../utils/actionConstants";
import fetchAPI from "../../utils/fetchAPI";
import environment from "../../utils/env";
//==============================================================================

export const deleteUserEffect = async (action, { dispatch, getState, take }) => {
  try {
    const { navigate } = action.payload;
    dispatch(setModalContent('deleteUserConfirmation'));

    const actionsArray = [
      APP_DELETE_USER,
      APP_KEEP_USER,
      'ui/setModalContent'
    ];

    const [newAction, ...rest] = await take(action => actionsArray.includes(action.type));

    dispatch(setModalContent(''));

    if (newAction.type === APP_DELETE_USER) {
      const url = `${environment.dbBaseURL}/user`
      const response = await fetchAPI(url, 'DELETE');
      dispatch(clearUserDetails())
      navigate(`/sign-in`)
      dispatch(setFlashMessage({ type: 'success', message: response.message }))
    }
  } catch (error) {
    dispatch(setFlashMessage({ type: 'error', message: 'There Was An Error Deleting Your Account. Please Try Again.' }))
    console.error('Error In The DeleteUserEffect: ', error)
  }
};
