//==============================================================================
// External:
import React from 'react';

// Internal:

//==============================================================================

const Issue = ({}) => {
  return (
    <div className='Issue__container'>
      <iframe src="https://querier.freshdesk.com/support/tickets/new" />
    </div>
  )
}

export default Issue;