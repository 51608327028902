//===================================================================================
// External:
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Internal:
import WhyQuerier from "./Subcomponents/WhyQuerier";
import HowDoesQuerierWork from "./Subcomponents/HowDoesQuerierWork";
import FAQs from "./Subcomponents/FAQs";
import { isDesktop } from "../../utils/utils";
import HeroSection from "./Subcomponents/HeroSection";
import { MobileHeroSection } from "./Subcomponents/MobileHeroSection";
//===================================================================================

const Home = () => {
  const [imageHeight, setImageHeight] = useState('');

  const calcImageHeight = () => {
    const ele = document.querySelector('.Home__mainImage');
    const height = ele?.getBoundingClientRect()?.height;
    return `${height - 25}px`;
  }

  useEffect(() => {
    setImageHeight(calcImageHeight());
  }, [])

  return (
    <div className="Home__container">
      {isDesktop() && <div className="Home__mainImage"></div>}
      {
        isDesktop() ?
          <HeroSection imageHeight={imageHeight} />
          : <MobileHeroSection />
      }
      <WhyQuerier />
      <HowDoesQuerierWork />
      {isDesktop() && <FAQs />}
    </div>
  )
};

export default Home;
