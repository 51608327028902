//==============================================================================
// Internal:
import environment from "../../utils/env";
import fetchAPI, {fetchWithPost } from "../../utils/fetchAPI";
import { setIsExtendingSession } from "../slices/ui.slice";
import { setExpirationTime } from "../slices/user.slice";
//==============================================================================

export const gmailDeleteDraftAPICall = async (userId, gmailDraftId) => {
  const url = `${environment.dbBaseURL}/mail/delete/${gmailDraftId}`;
  const returnedGmailInfo = await fetchAPI(url);
  return returnedGmailInfo;
}

export const gmailCreateDraftAPICall = async ({agent, email, query, user}) => {
  const url = `${environment.dbBaseURL}/mail/drafts/create`
  const requestBody = {
    to: agent.email,
    from: user.email,
    subject: query.emailSubject,
    message: email,
  }
  const returnedEmailInfo = await fetchWithPost(url, { ...requestBody });
  return returnedEmailInfo;
}

export const extendSessionAPICall = async (dispatch, resetTimer) => {
  const {expirationTime} = await fetchAPI(`${environment.dbBaseURL}/extend-session`);
  dispatch(setExpirationTime(expirationTime));
  dispatch(setIsExtendingSession(false));
  resetTimer()
}

export const updateQueryAPICall = async (existingQueryInfo, newQueryInfo, queryId) => {
  const url = `${environment.dbBaseURL}/queries/${queryId}`;
  const queryBody = { ...existingQueryInfo, ...newQueryInfo };
  const updatedQuery = await fetchWithPost(url, queryBody, 'PUT');
  return updatedQuery;
}