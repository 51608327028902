//==============================================================================
// Internal:
import { fetchWithPost } from "../../utils/fetchAPI";
import { setQuery } from "../slices/queries.slice";
import { setFlashMessage, setModalContent } from "../slices/ui.slice";
import { getUserDetails } from "../slices/user.slice";
import { objHasData } from '../../utils/utils';
import { gmailSendDraft } from "../actions/actions";
import { gmailRenewAuthToken } from "./gmailRenewAuthToken.effect";
import { gmailCreateDraftAPICall } from "../effectUtils/apiCalls";
import environment from "../../utils/env";
import * as ACTIONS from '../../utils/actionConstants';
//==============================================================================

export const gmailSendDraftEffect = async (action, { dispatch, getState, take }) => {
  const { agent, email, navigate, projectId, query, isFirstAttempt = true } = action.payload;

  try {
    const user = getUserDetails(getState())

    if (isFirstAttempt) {
      // await popup response from user:
      dispatch(setModalContent('googleSendConfirmation'));

      const actionsArray = [
        ACTIONS.APP_USER_AUTHORIZED_SEND_GMAIL_DRAFT,
        ACTIONS.APP_USER_CANCELLED_SEND_GMAIL_DRAFT,
        'ui/setModalContent'
      ];

      const [newAction, ...rest] = await take(action => actionsArray.includes(action.type));

      dispatch(setModalContent(''));

      if (newAction?.type === ACTIONS.APP_USER_CANCELLED_SEND_GMAIL_DRAFT) {
        return;
      }
    }

    const returnedEmailInfo = await gmailCreateDraftAPICall({ agent, email, query, user });

    // update query Gmail data:
    if (objHasData(returnedEmailInfo)) {
      const url = `${environment.dbBaseURL}/queries/${query._id}`;
      const params = {
        dateSentToInbox: new Date(),
        gmailDraftId: returnedEmailInfo.id,
        threadId: returnedEmailInfo.message.threadId
      }
      const queryBody = { ...query, ...params };
      const updatedQuery = await fetchWithPost(url, queryBody, 'PUT');
      dispatch(setQuery(updatedQuery));
      dispatch(setFlashMessage({ type: 'success', message: `Draft Successfully Sent To Your 'Drafts' Folder!` }))
    }

    navigate(`/project/${projectId}/queries`);
    // TODO: is this flash message right? Should it be above when we get in the user approval?
  } catch (err) {
    console.error('Error in GmailSendDraft Effect:', err);
    const error = JSON.parse(err)
    if (error.error.statusCode === 401 && isFirstAttempt) {
      await gmailRenewAuthToken(null, { dispatch, getState });
      dispatch(gmailSendDraft({ ...action.payload, isFirstAttempt: false }))
    } else {
      dispatch(setFlashMessage({
        type: 'error',
        message: 'There Was An Error Submitting Your Gmail Draft. Please Try Again.'
      }))
    }
  }
};