//==============================================================================
// External:
import React from 'react';

// Internal:
import QuestionDisplay from '../../components/QuestionDisplay/QuestionDisplay';
import { updateQueryTemplate } from '../../store/actions/actions';
//==============================================================================

const QueryTitle = ({ props }) => {
  const {
    dispatch,
    emailSubjectFS,
    projectId,
    setEmailSubjectFS,
    templateTitleFS,
  } = props;

  const handleQuerySubmit = (event) => {
    event.preventDefault();
    dispatch(updateQueryTemplate({
      emailSubject: emailSubjectFS,
      projectId,
      title: templateTitleFS,
    }));
  }

  return (
    <div className='QueryTitle__container'>
      <form
        className='QueryTitle__form'
        onSubmit={(event) => handleQuerySubmit(event)}
      >
        <div className='QueryTitle__emailSubject bold'>
          <label>Default Email Subject:</label>
          <input
            id='EmailSubjectInput'
            onChange={(event) => setEmailSubjectFS(event.target.value)}
            placeholder={emailSubjectFS}
            value={emailSubjectFS}
          />
          {/* <QuestionDisplay tooltipKey={'queryBuilderEmailSubject'} /> */}
        </div>
      </form>
    </div>
  )
}

export default QueryTitle;
