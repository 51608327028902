//==============================================================================
// External:
import React from 'react';
import { useNavigate } from 'react-router-dom';

// Internal:

//==============================================================================

export const MobileHeroSection = () => {
  const navigate = useNavigate();

  return (
    <div className='MobileHeroSection__container'>
      <div className="MobileHeroSection__textLine">
        Querying Sucks.
      </div>
      <div className='MobileHeroSection__textLine'>
        Querier Can Make It Suck Less.
      </div>
      <button onClick={() => navigate('/sign-up')}>
        Start Querying Today
      </button>
    </div>
  )
}