//==============================================================================
// External:
import React from 'react';
import SubstitutionEntry from '../../../SubstitutionEntry/SubstitutionEntry';
import Editor from '../../../Editor/Editor';
import QueryFinalEmail from '../../../QueryFinalEmail/QueryFinalEmail';
import PageSubtitle from '../../../PageSubtitle/PageSubtitle';

// Internal:

//==============================================================================

const QueryLetterEditor = ({
  substitutionTemplates,
  existingSubstitutionEntriesFS,
  existingQuerySubstitutions,
  setExistingSubstitutionEntriesFS,
  setNewSubstitutionEntriesFS,
  newSubstitutionEntriesFS,
  newQueryLetterText,
  agentId,
  emailSubjectFS,
  finalEmailHTML,
  setNewQueryLetterText,
}) => {

  const getSubEntry = (substitution) => {
    if (!existingSubstitutionEntriesFS || Object?.keys(existingSubstitutionEntriesFS)?.length === 0) return {};
    // return local substitution entry value matching the substitution in template
    return Object?.values(existingSubstitutionEntriesFS)?.filter(subEnt => subEnt.substitutionId === substitution._id)[0]
  }

  // update substitutionEntryFS w new text
  const handleSubstitutionEntriesInput = (substitutionEntry) => {
    if (Object.keys(existingQuerySubstitutions).includes(substitutionEntry.substitutionId)) {
      // handle changes to existing subs texts:
      setExistingSubstitutionEntriesFS({
        ...existingSubstitutionEntriesFS,
        [substitutionEntry.substitutionId]: { ...substitutionEntry } // use key of the substitutionTemplate as the key for the substitutionEntry
      })
    } else {
      // allow user to create new subs texts:
      const { _id, ...attrsToSave } = substitutionEntry;
      setNewSubstitutionEntriesFS({
        ...newSubstitutionEntriesFS,
        [substitutionEntry.substitutionId]: { ...attrsToSave } // use key of the substitutionTemplate as the key for the substitutionEntry
      })
    }
  }

  const handleTextInput = (input) => {
    setNewQueryLetterText(input); // update template with any changes made
  }

  const createSubstitutionList = Object.values(substitutionTemplates).map((substitution) => {
    return <SubstitutionEntry
      substitution={substitution}
      handleSubstitutionEntriesInput={handleSubstitutionEntriesInput}
      substitutionEntry={getSubEntry(substitution)}
    />
  });

  return (
    <div className='QueryLetterEditor__container'>
      <div className='QueryLetterEditor__substitutionsContainer'>
        <PageSubtitle
          title={'Add Text Substitutions'}
          shouldShowQuestionMark
          tooltipKey={'queryManagerFinalText'}
        />
        {createSubstitutionList}
      </div>
      <div className='QueryLetterEditor__editor'>
        <div className='SubstitutionFinalText__editor'>
          <PageSubtitle
            title={'Make Text Substitutions'}
            shouldShowQuestionMark
            tooltipKey={'queryManagerFinalText'}
          />
          <div>
            <Editor setValue={handleTextInput} text={newQueryLetterText} />
          </div>
        </div>
      </div>
      <div className='QueryLetterEditor__finalEmail'>
        <QueryFinalEmail
          agentId={agentId}
          emailSubject={emailSubjectFS}
          finalEmailHTML={finalEmailHTML}
        />
      </div>
    </div>
  )
}

export default QueryLetterEditor;